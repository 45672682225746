import * as React from 'react';
import { useContext, useEffect, useState, } from 'react';
import { Link, useParams } from 'react-router-dom';
import "./auth.css"
import logo from "./../../img/logo.png"
import axios from 'axios'
import { toast } from 'react-toastify'
import { useNavigate, } from 'react-router-dom'
import { UserContext } from '../../context/UserContext'
import { AUTH_API_PATH } from "./apiPath";
import { setActivationToken } from '../../utils/storage';
import inst from '../Application/api';
import * as Validator from '../../utils/validation'

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isValidPassword, setIsValidPassword] = useState(true)
  const userContext = useContext(UserContext);
  function handleUsernameChange(event) {
    setEmail(event.target.value);
  }

  const checkValidPassword = (e) =>
    setIsValidPassword(Validator.isValidPassword(e.target.value));

  function handlePasswordChange(event) {
    setIsValidPassword(true)
    setPassword(event.target.value);

  }


  useEffect(() => {
    if (userContext.user === false) {
      localStorage.clear();
      return navigate("/");
    }

    if (userContext.user === true) {
      return navigate("/applications");
    }
  }, [userContext.user]);

  const { setUser, user } = useContext(UserContext);

  const navigate = useNavigate()

  function handleSubmit(event) {
    event.preventDefault();
    if (email && password) {
      axios.post(AUTH_API_PATH + "/login", {
        email,
        password
      }).then((response) => {
        setUser(response)
        navigate('/applications')
        setActivationToken(response?.data?.access_token)
      }).catch((error) => {
        setIsValidPassword(false)
      })
    }
  }

  return (
    <div className='auth-container'>
      <div className='header-forLogin'>
        <img src={logo} alt='logo' className='logo-for-login' />
      </div>
      <div className='login-container'>
        <div className='pading'>
          <div className='names'>
            <h1 className='headerName'>Login to</h1>
            <h2 className='h2'>Orion Score</h2>
          </div>
          <form className='loginwrap' onSubmit={handleSubmit}>
            <label className='email-l'>
              <span className='style-email'>Email address</span>
              <div className='regul-inputs'>
                <input className='email-input' type="email" value={email} onChange={handleUsernameChange} />
              </div>
            </label>
            <label className='password-l'>
              <div className='regul-space'>
                <span className='style-pass'>Password</span>

              </div>
              <div className='regul-inputs'>
                <input className='password-input' style={isValidPassword == false ?{borderColor:'red'}:null} type="password" value={password} onChange={handlePasswordChange} />
                {isValidPassword == false ? <span className='errorMessage'>Incorrect email or password.</span> : null}
              </div>
              <div className='forForgot'>
                <div>

                </div>
                <span className='txt-log'><Link to="/forgot">Forgot Password?</Link> </span>
              </div>
            </label>
            <div className='buttons' >
              <button className='login-btn' type="submit">Log in</button>

              <div className='fin-this-page'>
                <span className='txt-log'>Don't have an account? <Link to="/register">Sign up</Link> </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;