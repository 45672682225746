import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {Link,useNavigate} from 'react-router-dom';
import "./auth.css"
import logo from "./../../img/logo.png"
import btn from "./../../img/linkdin.png"
import { Checkbox } from '@material-ui/core';
import { useSearchParams } from 'react-router-dom';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import {UserContext} from '../../context/UserContext';
import {AUTH_API_PATH} from "./apiPath";
import { setActivationToken } from '../../utils/storage';
import * as Validator from '../../utils/validation'
import { fontSize } from '@material-ui/system';




const Register = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const hash = urlParams.get("hash");
    const [checkCheckedPassword,setCheckcheckedPassWord] = useState(true)
    const [name, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email,setEamil] = useState('')
    const [checkedPassword,setCheckedPassWord] = useState('')
    const [isValidPassword, setIsValidPassword] = useState(true)
    const [error, setError] = useState(false)
    const [checked, setChecked] = useState(false)
    const navigate = useNavigate()

    const checkValidPassword = (e) =>
    setIsValidPassword(Validator.isValidPassword(e.target.value));

    const handleRegister = (event) => {
      event.preventDefault();
      const role = 0
        if(checkedPassword===password){
          if(!hash){
          axios.post(AUTH_API_PATH+"/register", {
            email,
            name,
            password,
            role
        }).then((e) => {
            navigate(`/OtpcheckRegister/${email}`)
        }).catch(() => {
          toast.error("Account with this email already exists")
          setError(true)
        })}else{
        axios.post(AUTH_API_PATH+"/register", {
          email,
          name,
          password,
          hash:hash,
          role
      }).then((e) => {
          navigate(`/OtpcheckRegister/${email}`)
      }).catch(() => {
        toast.error("Account with this email already exists")
        setError(true)
      })}
        }else{
          setCheckcheckedPassWord(false)
         
        }
       


    };

    const handleCheck = () => {
      setChecked(!checked)
    }

    const passwordChange = (e) => {
      checkValidPassword(e)
      setPassword(e.target.value)
    }

    const checkPasswordChange = (e) => {
      setCheckedPassWord(e.target.value)
    }

    return (
<div className='auth-container'>
<div className='header-forLogin'>
  <img src={logo} alt='logo' className='logo-for-login'/>
  {/* <div className='vector_1'></div>
          <div className='titles-wrapper'>
            <h1>WORLDWIDE INNOVATIONS</h1>
            <h5>IP Protection & Acquisition. Financing</h5>
          </div> */}
</div>
<div className='login-container'>
    <div className='names'>
      <h1 className='headerName'>Sign up to</h1>
      <h2 className='h2'>Orion Score</h2>
    </div>
<form className='loginwrap' onSubmit={handleRegister}>
<label className='email-l'>
<span className='style-email'>Full name</span>
  <div className='regul-inputs'>
  <input className='email-input' value={name} onChange={(e) => setUsername(e.target.value)} />
  </div>
</label>
<label className='email-l'>
<span className='style-email'>Email address</span>
  <div className='regul-inputs'>
  <input className='email-input' type="email" value={email} onChange={(e) => setEamil(e.target.value)} />
  </div>
</label>
<label className='password-l'>
  <div className='regul-space'>
  <span className='style-pass'>Password</span>
  </div>
  <div  className='regul-inputs'>
  <input className='password-input'style={isValidPassword == false ?{borderColor:'red'}:null} type="password" value={password} onChange={passwordChange}  />
  </div>
      <span style={{opacity:'0.4'}} className='style-email'>Minimum 8 characters long, containing number, symbol, uppercase and lowercase letters</span>
<span style={{paddingTop:'5px',}} className='style-email'>Repeat Password</span>
  <div className='regul-inputs'>
  <input className='password-input' type="password" style={checkedPassword == password || checkedPassword == '' ? null : {borderColor:'red'}} value={checkedPassword} onChange={checkPasswordChange} />
  </div>

   <div className='accept-wrapper'>
    <input id='accept' onClick={handleCheck} type='checkbox' required/>
    
   <Link to="https://www.orionscore.com/privacy-policy" target='_blank'> <label  className='txt-log-reg'>Accept Terms and Conditions</label></Link>
  </div> 
</label>
{/* {error ? <span className='errorMessageLogin'>Please Fill all Fields</span> : null} */}
 <div className='buttons' >  
  <button className='login-btn' style={!isValidPassword || checkedPassword != password ? {opacity:'0.5' , cursor: 'default'} : null} disabled={!isValidPassword}  type="submit">Register</button>
   <div className='fin-this-page'>
 <span className='have-account'>Have an account? <Link to="/">Sign in</Link> </span>
 </div>
  </div>       
</form>
</div>
</div> 
);
}   
  


 
export default Register;