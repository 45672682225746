import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Application from "../../Pages/Application/Application";
import { useNavigate, useParams } from "react-router-dom";
import inst from "../../utils/api";
import { UseSelector, useSelector } from "react-redux";
import api from '../../utils/api'
import { useDispatch } from "react-redux";
import { updateApplication } from "../../State/slice";
import { useState, useEffect } from "react";
import { numbers } from "../../Arrays/arrays";
import TextField from '@mui/material/TextField';
import { Autocomplete } from "@mui/material";
import { NumericFormat } from 'react-number-format';
import info from '../../img/info.png'


const SecondPart = () => {
  const {id} = useParams()

  const [application,setApplications] = useState(null)
  useEffect(() => {
      api.get(`applications/${id}`)
        .then(response => {
           setApplications(response)
           setCheck1(response.fast)
           setCheck2(response.satisfactory)
           setCheck3(response.easier)
           setCheck4(response.budget)
        })
        .catch(error => {
          console.error(error);
        });
    
  }, [])
  
const steps = window.innerWidth >= 760 ? [{label:"Step 1",url:`/application/step1/${id?id:0}`}, {label:"Step 2",url:`/application/step2/${id}`},{label:"Step 3",url:`/application/step3/${id}`}] : [{label:" ",url:`/application/step1/${id?id:0}}`}, {label:" ",url:`/application/step2/${id}`},{label:" ",url:`/application/step3/${id}`}];
  // useEffect()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [check1, setCheck1] = useState(0)
  const [check2, setCheck2] = useState(0)
  const [check3, setCheck3] = useState(0)
  const [check4, setCheck4] = useState(0)
  const [focus1, setFocus1] = useState(false)
  const [focus2, setFocus2] = useState(false)
  const [focus3, setFocus3] = useState(false)
  const [focus4, setFocus4] = useState(false)
  const [focus5, setFocus5] = useState(false)
  const [focus6, setFocus6] = useState(false)
  const [focus7, setFocus7] = useState(false)
  const [focus8, setFocus8] = useState(false)
  const [focus9, setFocus9] = useState(false)
  const [focus10, setFocus10] = useState(false)
  const [focus11, setFocus11] = useState(false)
  const [focus12, setFocus12] = useState(false)

  useEffect(()=>{
    window.scrollTo(0, 0)
  }, [])

  const handleAddApplication = (e) => {
    e.preventDefault();


   
    const newApplication = {
      clientRetention: parseInt(e.target.clientRetention.value.replaceAll(',', '')),
      desiredAmountToRaise: parseInt(e.target.desiredAmount.value.replaceAll(',', '')),
      plannedRevenueInYear: parseInt(e.target.plannedRevenueInYear.value.replaceAll(',', '')),
      plannedRevenueLast12Months: parseInt(e.target.revenueTwelve.value.replaceAll(',', '')),
      plannedRevenueInThreeYear: parseInt(e.target.plannedRevenueInThreeYear.value.replaceAll(',', '')),
      fundsReceived: parseInt(e.target.fundsReceived.value.replaceAll(',', '')),
      runawayMonths: parseInt(e.target.runawayMonths.value.replaceAll(',', '')),
      mainCompetitors: e.target.mainCompetitors.value,
      competitiveAdvantage: e.target.competitiveAdvantage.value,
      fast: check1,
      satisfactory: check2,
      easier: check3,
      budget: check4,
    };

    api.put(`applications/${id}`,{...application,...newApplication}).then((response)=>{
      navigate(`/application/step3/${response.id}`);

    })
    
  };

  const firstInput = (e) => {
    setCheck2(e.target.innerText)
    setFocus9(false)
  }

  const secondInput = (e) => {
    setCheck3(e.target.innerText)
    setFocus10(false)
  }

  const thirdInput = (e) => {
    setCheck4(e.target.innerText)
    setFocus11(false)
  }


  return (
    <Application>
      <div className="Part-container">
      <div className="pageName">
            <h1 className="headerName">Application</h1>
            <Stepper className="steps" activeStep={1} alternativeLabel>
              {steps?.map((i,key) => (
                <Step
                onClick={()=>navigate(i.url)}
                sx={{
'& .MuiStepLabel-root .Mui-active': {
color: '#0F1E38', // circle color (ACTIVE)
},
'& .MuiStepLabel-root .Mui-completed': {
  color: '#5CB670', // circle color (COMPLETED)
},
cursor:'pointer'
                }}
                key={key}>
                  <StepLabel>{i.label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
        <div className="filds-container-2">
          <form className="form" onSubmit={handleAddApplication}>
            <div className="inputs-wrapper">
              <div className="left-part">
                <label onMouseOut={() => setFocus1(false)} onMouseOver={() => setFocus1(true)} className="lable-for-apk">
                {focus1 ? <div className="help-text-wrapper">
                <img src={info} width={20}/>
                <span className="help-text">Planned Revenue means the projected revenue to be received in the applicable calendar year. </span></div> : null}
                  <span className="xs">Planned Revenue in 1 year in USD</span>
                  <NumericFormat 
                    value={application?.plannedRevenueInYear}
                     thousandSeparator="," className="in" min={0} name="plannedRevenueInYear" required />
                </label>
                <label onMouseOut={() => setFocus2(false)} onMouseOver={() => setFocus2(true)} className="lable-for-apk">
                {focus2 ? <div className="help-text-wrapper">
                <img src={info} width={20}/>
                <span className="help-text">Planned Revenue means the revenue that is expected to be received in the coming three calendar years combined. </span></div> : null}
                  <span className="xs">Planned Revenue in 3 years in USD</span>
                  <NumericFormat  value={application?.plannedRevenueInThreeYear} thousandSeparator="," className='in' min={0} name="plannedRevenueInThreeYear" required />
                </label>
                <label onMouseOut={() => setFocus3(false)} onMouseOver={() => setFocus3(true)} className="lable-for-apk">
                {focus3 ? <div className="help-text-wrapper">
                <img src={info} width={20}/>
                <span className="help-text">Runway means the number of months left until the company runs out of cash.</span></div> : null}
                  <span className="xs">Runway Months</span>
                  <NumericFormat  value={application?.runawayMonths} thousandSeparator="," className='in' min={0} name="runawayMonths" required />
                </label>
                <label onMouseOut={() => setFocus4(false)} onMouseOver={() => setFocus4(true)} className="lable-for-apk">
                  {focus4 ? <div className="help-text-wrapper">
                <img src={info} width={20}/>
                <span className="help-text">The customer retention rate is the percentage of existing customers who remain customers after a given period.</span></div> : null}
                  <span className="xs">Client Retention Rate (%)</span>
                  <NumericFormat value={application?.clientRetention} thousandSeparator="," className='in' min={0} name="clientRetention" />
                </label>
              </div>
              <div className="right-part">
                <label className="lable-for-apk">
                  <span className="xs">Revenue During last 12 months in USD</span>
                  <NumericFormat value={application?.plannedRevenueLast12Months} thousandSeparator="," className='in' min={0} name="revenueTwelve" />
                </label>
                <label onMouseOut={() => setFocus5(false)} onMouseOver={() => setFocus5(true)} className="lable-for-apk">
                {focus5 ? <div className="help-text-wrapper">
                <img src={info} width={20}/>
                <span className="help-text">The amount of money investors offer to a particular startup.</span></div> : null}
                  <span className="xs">Funds Received in USD</span>
                  <NumericFormat value={application?.fundsReceived} thousandSeparator="," className="in" min={0} id="fundsReceived" name="fundsReceived" required />
                </label>
                <label onMouseOut={() => setFocus6(false)} onMouseOver={() => setFocus6(true)} className="lable-for-apk">
                {focus6 ? <div className="help-text-wrapper">
                <img src={info} width={20}/>
                <span className="help-text">The amount of money a startup needs to raise. </span></div> : null}
                  <span className="xs">Desired Amount to raise in USD</span>
                  <NumericFormat value={application?.desiredAmountToRaise} thousandSeparator="," className="in" min={0} id="desiredAmount" name="desiredAmount" required />
                </label>
              </div>
            </div>
            <div className="textareas">
            <label onMouseOut={() => setFocus7(false)} onMouseOver={() => setFocus7(true)} className="lable-for-apk">
                {focus7 ? <div className="help-text-wrapper">
                <img src={info} width={20}/>
                <span className="help-text">Please tell us about your competitors in 100 words or less. </span></div> : null}
                <span className="xs">Name Main Competitors</span>
                <textarea  defaultValue={application?.mainCompetitors} name="mainCompetitors" className="in-area" rows='6'></textarea>
              </label>
              <label onMouseOut={() => setFocus8(false)} onMouseOver={() => setFocus8(true)} className="lable-for-apk">
                {focus8 ? <div className="help-text-wrapper">
                <img src={info} width={20}/>
                <span className="help-text">Please tell us about anything that gives your company an edge over your competitors in 100 words or less.</span></div> : null}
                <span className="xs">What is your Competitive Advantage</span>
                <textarea defaultValue={application?.competitiveAdvantage} name="competitiveAdvantage" className="in-area" rows='6'></textarea>
              </label>
            </div>
            <div className="radios">
            <div className="radios-wrapper">
            <label className="lable-for-apk">
              <span className="xs">Evaluate your Product on being faster than Competitors</span>
              <div className='radio-flex'>
                <div>
                <div className="radio-wrapper">
                  <label htmlFor="a">1</label>
                <input onChange={(e) => setCheck1(1)} name="fast" id="a" className="radio" type="radio" value={1} checked={check1 ===1}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="b">2</label>
                <input onChange={(e) => setCheck1(2)} name="fast" id="b" className="radio" type="radio" value={2} checked={check1 ===2} ></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="c">3</label>
                <input onChange={(e) => setCheck1(3)} name="fast" id="c" className="radio" type="radio" value={3} checked={check1 ===3}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="d">4</label>
                <input onChange={(e) => setCheck1(4)} name="fast" id="d" className="radio" type="radio" value={4} checked={check1 ===4}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="e">5</label>
                <input onChange={(e) => setCheck1(5)} name="fast" id="e" className="radio" type="radio" value={5} checked={check1 ===5}></input>
                </div>
                </div>
                <div>
                <div className="radio-wrapper">
                  <label htmlFor="f">6</label>
                <input onChange={(e) => setCheck1(6)} name="fast" id="f" className="radio" type="radio" value={6} checked={check1 ===6}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="g">7</label>
                <input onChange={(e) => setCheck1(7)} name="fast" id="g" className="radio" type="radio" value={7} checked={check1 ===7}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="h">8</label>
                <input onChange={(e) => setCheck1(8)} name="fast" id="h" className="radio" type="radio" value={8} checked={check1 ===8}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="i">9</label>
                <input onChange={(e) => setCheck1(9)} name="fast" id="i" className="radio" type="radio" value={9} checked={check1 ===9}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="j">10</label>
                <input onChange={(e) => setCheck1(10)} name="fast" id="j" className="radio" type="radio" value={10} checked={check1 ===10}></input>
                </div>
                </div>
              </div>
            </label>
            <label onMouseOut={() => setFocus9(false)} onMouseOver={() => setFocus9(true)} className="lable-for-apk">
                {focus9 ? <div className="help-text-wrapper1">
                <img src={info} width={20}/>
                <span className="help-text1">How well your company's products, services, and overall customer experience meet customer expectations.</span></div> : null}
              <span className="xs">Evaluate your Product on being more client satisfactory than Competitors</span>
              <div className='radio-flex'>
              <div>
                <div className="radio-wrapper">
                  <label htmlFor="k">1</label>
                <input onChange={(e) => setCheck2(1)} name="satisfactory" id="k" className="radio" type="radio" value={1} checked={check2===1}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="l">2</label>
                <input onChange={(e) => setCheck2(2)} name="satisfactory" id="l" className="radio" type="radio" value={2} checked={check2===2}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="m">3</label>
                <input onChange={(e) => setCheck2(3)} name="satisfactory" id="m" className="radio" type="radio" value={3} checked={check2===3} ></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="n">4</label>
                <input onChange={(e) => setCheck2(4)} name="satisfactory" id="n" className="radio" type="radio" value={4} checked={check2===4}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="o">5</label>
                <input onChange={(e) => setCheck2(5)} name="satisfactory" id="o" className="radio" type="radio" value={5} checked={check2===5}></input>
                </div>
                </div>
                <div>
                <div className="radio-wrapper">
                  <label htmlFor="p">6</label>
                <input onChange={(e) => setCheck2(6)} name="satisfactory" id="p" className="radio" type="radio" value={6} checked={check2===6}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="q">7</label>
                <input onChange={(e) => setCheck2(7)} name="satisfactory" id="q" className="radio" type="radio" value={7} checked={check2===7}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="r">8</label>
                <input onChange={(e) => setCheck2(8)} name="satisfactory" id="r" className="radio" type="radio" value={8} checked={check2===8}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="s">9</label>
                <input onChange={(e) => setCheck2(9)} name="satisfactory" id="s" className="radio" type="radio" value={9} checked={check2===9}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="t">10</label>
                <input onChange={(e) => setCheck2(10)} name="satisfactory" id="t" className="radio" type="radio" value={10} checked={check2===10}></input>
                </div>
                </div>
              </div>
            </label>
            <label onMouseOut={() => setFocus10(false)} onMouseOver={() => setFocus10(true)} className="lable-for-apk">
                {focus10 ? <div className="help-text-wrapper">
                <img src={info} width={20}/>
                <span className="help-text">How easily users can use your product.</span></div> : null}
              <span className="xs">Evaluate your Product on being easier to use than Competitors</span>
              <div className='radio-flex'>
              <div>
                <div className="radio-wrapper">
                  <label htmlFor="u">1</label>
                <input onChange={(e) => setCheck3(1)} name="easier" id="u" className="radio" type="radio" value={1} checked={check3===1} ></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="v">2</label>
                <input onChange={(e) => setCheck3(2)} name="easier" id="v" className="radio" type="radio" value={2} checked={check3===2} ></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="w">3</label>
                <input onChange={(e) => setCheck3(3)} name="easier" id="w" className="radio" type="radio" value={3}checked={check3===3} ></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="x">4</label>
                <input onChange={(e) => setCheck3(4)} name="easier" id="x" className="radio" type="radio" value={4}checked={check3===4} ></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="y">5</label>
                <input onChange={(e) => setCheck3(5)} name="easier" id="y" className="radio" type="radio" value={5}checked={check3===5} ></input>
                </div>
                </div>
                <div>
                <div className="radio-wrapper">
                  <label htmlFor="z">6</label>
                <input onChange={(e) => setCheck3(6)} name="easier" id="z" className="radio" type="radio" value={6}checked={check3===6} ></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="a1">7</label>
                <input onChange={(e) => setCheck3(7)} name="easier" id="a1" className="radio" type="radio" value={7}checked={check3===7} ></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="a2">8</label>
                <input onChange={(e) => setCheck3(8)} name="easier" id="a2" className="radio" type="radio" value={8}checked={check3===8} ></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="a3">9</label>
                <input onChange={(e) => setCheck3(9)} name="easier" id="a3" className="radio" type="radio" value={9}checked={check3===9} ></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="a4">10</label>
                <input onChange={(e) => setCheck3(10)} name="easier" id="a4" className="radio" type="radio" value={10}checked={check3===10} ></input>
                </div>
                </div>
              </div>
            </label>
            <label onMouseOut={() => setFocus11(false)} onMouseOver={() => setFocus11(true)} className="lable-for-apk">
                {focus11 ? <div className="help-text-wrapper">
                <img src={info} width={20}/>
                <span className="help-text">That is, not too expensive; affordable.</span></div> : null}
              <span className="xs">Evaluate your Product on being more budget friendly than Competitors</span>
              <div className='radio-flex'>
              <div>
                <div className="radio-wrapper">
                  <label htmlFor="a5">1</label>
                <input onChange={(e) => setCheck4(1)} name="budget" id="a5" className="radio" type="radio" value={1} checked = {check4===1} ></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="a6">2</label>
                <input onChange={(e) => setCheck4(2)} name="budget" id="a6" className="radio" type="radio" value={2} checked = {check4===2} ></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="a7">3</label>
                <input onChange={(e) => setCheck4(3)} name="budget" id="a7" className="radio" type="radio" value={3} checked = {check4===3} ></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="a8">4</label>
                <input onChange={(e) => setCheck4(4)} name="budget" id="a8" className="radio" type="radio" value={4} checked = {check4===4} ></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="a9">5</label>
                <input onChange={(e) => setCheck4(5)} name="budget" id="a9" className="radio" type="radio" value={5} checked = {check4===5} ></input>
                </div>
                </div>
                <div>
                <div className="radio-wrapper">
                  <label htmlFor="a10">6</label>
                <input onChange={(e) => setCheck4(6)} name="budget" id="a10" className="radio" type="radio" value={6}checked = {check4===6} ></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="a11">7</label>
                <input onChange={(e) => setCheck4(7)} name="budget" id="a11" className="radio" type="radio" value={7} checked = {check4===7} ></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="a12">8</label>
                <input onChange={(e) => setCheck4(8)} name="budget" id="a12" className="radio" type="radio" value={8} checked = {check4===8} ></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="a13">9</label>
                <input onChange={(e) => setCheck4(9)} name="budget" id="a13" className="radio" type="radio" value={9} checked = {check4===9} ></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="a14">10</label>
                <input onChange={(e) => setCheck4(10)} name="budget" id="a14" className="radio" type="radio" value={10}checked = {check4===10} ></input>
                </div>
                </div>
              </div>
            </label>
            </div>
            </div>
            <div className="radios-wrapper-mobile">
            <span className="xs">Evaluate your Product on being faster than Competitors</span>
            <label className="lable-for-apk-number">
                
                <Autocomplete className="inM" id="a" name="fast" required
                size="small"
                value={check1}
                onChange={(e)=>setCheck1(e.target.innerText)}
                options={numbers}
                renderInput={(params) => <TextField value={check1} {...params}/>}
                />
            </label>
            <span className="xs">Evaluate your Product on being more client satisfactory than Competitors</span>
            <label onMouseOut={() => setFocus9(false)} onMouseOver={() => setFocus9(true)} className="lable-for-apk-number">
                {focus9 ? <div className="help-text-wrapper">
                <img src={info} width={20}/>
                <span className="help-text">How well your company's products, services, and overall customer experience meet customer expectations.</span></div> : null}
                <Autocomplete className="inM" id="k" name="satisfactory" required
                size="small"
                value={check2}
                onChange={firstInput}
                options={numbers}
                renderInput={(params) => <TextField    value={check2} {...params}/>}
                />
            </label>
            <span className="xs">Evaluate your Product on being easier to use than Competitors</span>
            <label onMouseOut={() => setFocus10(false)} onMouseOver={() => setFocus10(true)} className="lable-for-apk-number">
                {focus10 ? <div className="help-text-wrapper">
                <img src={info} width={20}/>
                <span className="help-text">How easily users can use your product.</span></div> : null}
                <Autocomplete className="inM" id="u" name="easier" required
                 value={check3}
                size="small"
                onChange={secondInput}
                options={numbers}
                renderInput={(params) => <TextField  value={check3} {...params}/>}
                />
            </label>
            <span className="xs">Evaluate your Product on being more budget friendly than Competitors</span>
            <label onMouseOut={() => setFocus11(false)} onMouseOver={() => setFocus11(true)} className="lable-for-apk-number">
                {focus11 ? <div className="help-text-wrapper">
                <img src={info} width={20}/>
                <span className="help-text">That is, not too expensive; affordable.</span></div> : null}
                <Autocomplete className="inM" id="a5" name="budget" required
                 value={check4}
                size="small"
                onChange={thirdInput}
                options={numbers}
                renderInput={(params) => <TextField       value={check4} {...params}/>}
                />
            </label>
            </div>
            <div className="btn-next-2">
              <button type="submit" className="next-btn">
                Next
              </button>
            </div>
          </form>

        </div>
      </div>
    </Application>
  );
};

export default SecondPart;