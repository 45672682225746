import React, { useState } from 'react'
import "./Header.css"
import vec from "./../../img/Vector.png"
import logo from './../../img/logo.png'
import logout from "./../../img/loguut.png"
import { Link } from 'react-router-dom';

function Header() {  

  const [isOpen, setIsOpen] = useState(false)
   
  const openPanel = () => {
    setIsOpen(!isOpen)
  }
   
  return (
    <div className='header'>
<div className='headerIcon'>
    <img src={logo} width="100px"/>
    <img src={vec} width={50} className='mult' onClick={openPanel} />
 </div> 
 {isOpen == true ? 
 <div className='nav-bar'>
  <Link onClick={() => setIsOpen(false)} to = "/applications">Applications</Link>
  <Link onClick={() => setIsOpen(false)} to = "/">Log Out<img width="20px" src={logout}/></Link>
 </div> : null}
 </div>
  )
}

export default Header