import * as React from 'react';
import { useContext, useEffect, useState } from 'react' ;
import { Link } from 'react-router-dom';
import "./auth.css"
import { useParams } from 'react-router-dom';
import logo from "./../../img/logo.png"
import btn from "./../../img/linkdin.png"
import { Checkbox } from '@material-ui/core';
import axios from 'axios'
import { toast } from 'react-toastify'
import { useNavigate, } from 'react-router-dom'
import { UserContext } from '../../context/UserContext'
import { AUTH_API_PATH } from "./apiPath";
import api from '../../utils/api'

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword,setConfirmPassword] = useState('')
  const {email} = useParams()
  const navigate = useNavigate()

  function handleSubmit(event) {
    event.preventDefault();
    axios.post(AUTH_API_PATH+"/reset-password", {
        password,
        confirmPassword,
        email   
    }).then((e) => {
        navigate('/')
    }).catch(() => {
    })
  }

    return (  
      <div className='auth-container'>
        <div className='header-forLogin'>
          <img src={logo} alt='logo' className='logo-for-login'/>
          {/* <div className='vector_1'></div>
          <div className='titles-wrapper'>
            <h1>WORLDWIDE INNOVATIONS</h1>
            <h5>IP Protection & Acquisition. Financing</h5>
          </div> */}
        </div>
        <div className='login-container'>
          <div className='pading-forReset'>
            <div className='names'>
              <h1 className='headerName'>Reset Password</h1>
              <h2 className='h2'>Orion Score</h2>
            </div>
      <form className='Forgotwrap' onSubmit={handleSubmit}>
      <label className='password-l'>
          <div className='regul-space'>
          <span className='style-pass'>New Password</span>
          </div>
          <div  className='regul-inputs'>
          <input className='password-input' type="password" value={password} onChange={(e)=>setPassword(e.target.value)} />
          </div>
          <div className='regul-space'>
          <span className='style-pass'>Repeat Password</span>
          </div>
          <div  className='regul-inputs'>
          <input className='password-input' type="password" value={confirmPassword} onChange={(e)=>setConfirmPassword(e.target.value)} />
          </div>
        </label>
        <div className='buttons' >  
          <button className='login-btn' type="submit">Reset </button>
       </div>
      </form>
      </div>
        </div>
   </div> 
  );
}
 
export default ResetPassword;