import React, { useState } from "react";
import "./Application.css";

import { idContext } from "../../context/idContext";
import Header from "../../components/Header/Header";
import Menu from "../../components/Menu/Menu";
import Firstpaart from "../../components/Parts/Firstpaart";
import Secondpart from "../../components/Parts/Secondpart";
import Thirdpart from "../../components/Parts/Thirdpart";
import Allapk from "../../components/Allapk";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function Application({ children }) {
  const [applications, setApplications] = useState([]);
 
  const [clicked, isClicked] = useState(false);

  return (
    <div className="root1" >
    <Header/>
        <Menu/>
      <div className="app-container">
        {children}
      </div>
    </div>
  );
}

export default Application;