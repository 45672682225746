import React, { useState, useEffect } from 'react'
import Score from '../Pages/Score/Score';
import inst from './../Pages/Application/api'
import { useParams } from 'react-router-dom';
import { Chart as ChartJS, ArcElement, plugins } from 'chart.js'
import { Doughnut } from "react-chartjs-2";
import jsPDF from 'jspdf';
import 'react-circular-progressbar/dist/styles.css';
import download from '../img/download.png'
import html2canvas from 'html2canvas';
import { useNavigate } from 'react-router-dom';
import success from '../img/success.png'
import danger from '../img/danger.png'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { SpinnerCircular } from "spinners-react";
import logo from '../img/logo-background.jpg'
import Application from '../Pages/Application/Application';
import successbird from '../img/success-bird.png'
import { toast } from 'react-toastify';
import axios from 'axios'
import { AUTH_API_PATH } from '../Pages/auth/apiPath';
import { getActivationToken } from '../utils/storage';

ChartJS.register(
  ArcElement,
)

function Score1() {

  const token = getActivationToken()

  const navigate = useNavigate()
  let [data, setData] = useState(null);
  let [data1, setData1] = useState(null)
  const [evalutions,setEvalutions] = useState([])
  const [value, setValue] = useState(null)
  const [isPrinting, setIsPrinting] = useState(false)
  const [name, setName] = useState()

  const { id } = useParams();
  const [currentScore, setCurrentScore] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (id) {
      inst.get(process.env.REACT_APP_API_URI + `/api/applications/${id}`)
        .then(response => {
          setCurrentScore(JSON.parse(response.data.dms))
          setName(response.data.startupName)
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [])
  const score = currentScore?.totalScore
  const startColor = window.getComputedStyle(document.body, '').getPropertyValue('--grad-1');
  const endColor = window.getComputedStyle(document.body, '').getPropertyValue('--grad-2');

  const viewInfo = () => {
    navigate(`/applications/info/${id}`)
  }

  useEffect(() => {
       let arr = []
       arr.push(currentScore?.evalutions)
       setEvalutions(arr)
    {

     arr.evalutions?.map((i) => {
        return setValue(i?.value?.toString())
      })
    }
  }, [currentScore])


  const gradient = React.useMemo(() => {
    const ctx = document.createElement('canvas').getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 900);
    const stopPercentage = score >= 250 ? score : 0;
    gradient.addColorStop(0, startColor);
    gradient.addColorStop(stopPercentage / 1000, startColor);
    gradient.addColorStop(stopPercentage / 2500, endColor);
    gradient.addColorStop(1, endColor);
    return gradient;
  }, [score]);

  useEffect(() => {

    setData({
      labels: ['yes', 'no'],
      datasets: [{
        cutout: '65%',
        label: "Poll",
        data: [Math.round(score), (500 - Math.round(score))],
        backgroundColor: [gradient, "#ededed"],
        borderWidth: 0,
        width: '10px'

      }],
    })

    setData1({
      labels: ['yes', 'no'],
      datasets: [{
        cutout: '65%',
        label: "Poll",
        data: [Math.round(value), (100 - Math.round(value))],
        backgroundColor: [gradient, "#ededed"],
        borderWidth: 0,
        width: '10px'

      }],
    })
  }, [score])

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      }
    },
  }
  const textCenter = {
    id: 'textCenter',
    beforeDatasetsDraw(chart) {
      const { ctx, data } = chart;
      ctx.save();
      ctx.font = "bolder 20px sans-serif";
      ctx.fillStyle = "#1998A0";
      ctx.textBaseline = 'middle';
      ctx.fillText(`${Math.round(data.datasets[0].data[0])}`, chart.getDatasetMeta(0).data[0].x - 34, chart.getDatasetMeta(0).data[0].y)
      ctx.fillStyle = "#1998A0";
      ctx.fillText(`/500`, chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y)
    }
  }


  const downLoadPdf = () => {
    setIsPrinting(true)
    setTimeout(() => {
      const input = document.querySelector('.columnWrapper');
      html2canvas(input)
        .then((canvas) => {
          let imgWidth = window.innerWidth < 850 ? 50 : 208;
          let imgHeight = canvas.height * imgWidth / canvas.width;
          const imgData = canvas.toDataURL('img/png');
          const pdf = new jsPDF('p', 'mm', 'a3');
          pdf.addImage(imgData, 'PNG', window.innerWidth < 850 ? 120 : 47, 0, imgWidth, imgHeight);
          pdf.save("Orion_Score.pdf");
          setIsPrinting(false)
        })
    }, 1000)
  }

  const purchaseLicense = () => {
    inst.post(AUTH_API_PATH + "/payments", {
      id: parseInt(id),
    }).then((e) => {
      console.log(e.data)
      window.location.replace(e.data);
    }).catch(() => {
      toast.error("Failed")
    })
  }



  return (
    <Application>
        <div className={isPrinting == true ? 'contoler-score-print' : 'contoler-score'}>
          <div className='columnWrapper'>
            {isPrinting == true ? <div className='logo'><img src={logo} width={200} />
            </div> : null}
            <div className='topPart'>
              <div className='title-wrapper'>
                <h3 className='title-h3'>{name ? name : "Startup Name"} Orion Score Report</h3>
                <p className='title-p'>
                  {currentScore?.genDescription}
                </p>
                <button onClick={purchaseLicense} className='purchase'>Purchase For $19.99</button>
              </div>
              <div id='forPrint' className='filds-container-score'>

                <div className='char-container'>
                  <div className='cricle'>
                    {data ? (<Doughnut
                      style={{ height: '200px', width: "200px", margin: '15px' }}
                      data={data}
                      options={options}
                      plugins={[textCenter]}
                    />) : null}

                  </div>
                </div>
              </div>
            </div>
            <div className={isPrinting == true ? 'componentsWrapper-print' : 'componentsWrapper'}>
              {evalutions?.map((i, key) => (
                key == 0 ? <div key={key} className={isPrinting == true ? 'info-wrapper1-print' : 'info-wrapper1'}>
                  <div className='nameAndRanges'>
                    <div><h3 className={isPrinting == true ? 'title-print' : 'title'}>{i?.title}</h3></div>
                    <div className='Application-color'>
                      <h3 className={isPrinting == true ? 'text-assessment-print' : 'text-assessment'}>{i?.assessment}</h3>
                      <div className=
                        {isPrinting == true ? 'chartText-wrapper-print' : 'chartText-wrapper'}>{currentScore ? (
                          <><CircularProgressbar sx={{ width: "10%" }} value={Math.round(i?.value)}
                            styles={buildStyles({
                              textSize: '20px',
                              pathColor: `${i?.value < 45 ? 'red' : i?.value > 45 && i?.value < 75 ? 'orange' : 'green'}`,
                              textColor: `${i?.value < 45 ? 'red' : i?.value > 45 && i?.value < 75 ? 'orange' : 'green'}`,
                              backgroundColor: 'red',
                            })}
                            text={Math.round(i?.value) + " %"} /></>) : null}
                        <h4 className='sectionDesc'>{i?.sectionDesc}</h4>
                      </div>
                    </div>
                  </div>
                  <div className={isPrinting == true ? 'massages-print' : 'massages'}>
                    {i?.positiveMessages.length ?
                      <div className='icons'>
                        <img src={success} width={15} />
                        <div><h3 className={isPrinting == true ? 'text-title-print' : 'text-title'}>Things to focus on to make you more competitive.</h3>
                          {i?.positiveMessages.map((message, uid) => {
                            return <li key={uid} className={isPrinting == true ? 'text-print' : 'text'}>{message}</li>
                          })}
                        </div>
                      </div>
                      : null}
                    {i?.negativeMessages.length ?
                      <div className='icons'>
                        <img src={danger} width={15} />
                        <div><h3 className={isPrinting == true ? 'text-title-print' : 'text-title'}>Things to focus on to make you more competitive.</h3>
                          {i?.negativeMessages.map((message, uid) => {
                            return <li key={uid} className={isPrinting == true ? 'text-print' : 'text'}>{message}</li>
                          })}
                        </div>
                      </div>
                      : null}
                  </div>
                </div> : null
              ))}

            </div>
            {isPrinting == false ? <a onClick={viewInfo} className='view-data'>View Submitted Data</a> : null}
          </div>
          <div className='black-background'>
            <p className='purchase-text'>
            Want to boost your chances of obtaining funds? Unlock the Orion Pro report and get access to the precise report, which will emphasize competitive advantages as well as pain points to work on.
            </p>
            <button onClick={purchaseLicense} className='purchase-bottom'>Purchase For $19.99</button>
          </div>
          <div className='componentsWrapper'>
            <div className='info-wrapper1'>
              <h1 className='advantages-title'>ORION SCORE REPORT ADVANTAGES</h1>
              <p className='title-p-advantages'>
              The Orion Score report gives a standardized assessment of startup inevitability and recommendations for startup founders on increasing their chances of getting funded.
              </p>
              <div className='sections-assessments'>
                <h2>
                The Orion Score Report includes assessments in five sections:
                </h2>
              </div>
              <div className='sections-table'>
                <table className='rows'>
                  <tr className='sections-title'>
                    <th><div className='sections-title-style'>Startup Investability Evaluation Criteria</div></th>
                    <th> <div className='sections-title-style'>Things that you are great at and that contributed to your startup Orion score.</div></th>
                    <th><div className='sections-title-style-with-P'>Things to focus on to increase your Orion Score and chances of getting funded</div></th>
                  </tr>
                  <tr>
                      <td><h3 className='rows-title'>Founders and Team</h3></td>
                      <td><img src={successbird}></img></td>
                      <td><img src={successbird}></img></td>
                    </tr>
                    <tr >
                      <td><h3 className='rows-title'>Traction</h3></td>
                      <td><img src={successbird}></img></td>
                      <td><img src={successbird}></img></td>
                    </tr>
                    <tr >
                      <td><h3 className='rows-title'>Scalability and Growth Potential</h3></td>
                      <td><img src={successbird}></img></td>
                      <td><img src={successbird}></img></td>
                    </tr>
                    <tr>
                      <td><h3 className='rows-title'>Competitive Advantage, Risks, Market Fit</h3></td>
                      <td><img src={successbird}></img></td>
                      <td><img src={successbird}></img></td>
                    </tr>
                    <tr >
                      <td><h3 className='rows-title'>Next Generation Disruptive Product</h3></td>
                      <td><img src={successbird}></img></td>
                      <td><img src={successbird}></img></td>
                    </tr>
              
                </table>
              </div>
            </div>
          </div>
        </div>
        {isPrinting && <div className='printing'>
          <SpinnerCircular sx={{ margin: '20px' }}
            size="200"
            thickness="200"
            color="#1998A0"
            secondaryColor="silver" />
        </div>
        }
    </Application>
  )
}

export default Score1