import { Fragment, useState, useEffect } from "react";
import Founders from "../Founders";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Application from "../../Pages/Application/Application";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setApplications, updateApplication } from "../../State/slice";
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { $api } from "../../api"
import ChoseFilr from "./../../img/Frame 5115 (1).png"
import * as Validator from '../../utils/validation'
import DeleteFounder from "../deleteFounders";
import api from '../../utils/api'
import Hello from '../../img/hello.png'
import close from '../../img/close.png'
import { NumericFormat } from 'react-number-format';
import info from '../../img/info.png'

// import xlsx from "json-as-xlsx"


const Thirdpart = () => {
  const {id} = useParams()
  const [isValidUrl, setIsValidUrl] = useState(true)
  const [user,setUser] = useState(null)

  const [application,setApplications] = useState({})
  useEffect(() => {
      api.get(`applications/${id}`)
        .then(response => {
           setApplications(response)
        })
        .catch(error => {
          console.error(error);
        });
    
  }, [])
  const steps = window.innerWidth >= 760 ? [{label:"Step 1",url:`/application/step1/${id?id:0}}`}, {label:"Step 2",url:`/application/step2/${id}`},{label:"Step 3",url:`/application/step3/${id}`}] :  [{label:" ",url:`/application/step1/${id?id:0}}`}, {label:" ",url:`/application/step2/${id}`},{label:" ",url:`/application/step3/${id}`}] ;
  const checkValidUrl = (e) =>
    setIsValidUrl(Validator.isValidUrl(e.target.value));

  const [file,setFile] = useState()
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [scoreId, setScoreId] = useState(null)
  const [focus1, setFocus1] = useState(false)
  const [focus2, setFocus2] = useState(false)
  const [focus3, setFocus3] = useState(false)
  const [focus4, setFocus4] = useState(false)
  const [focus5, setFocus5] = useState(false)

  useEffect(()=>{
    window.scrollTo(0, 0)
  }, [])

  async function uploadFile(){
      const body = new FormData();
      body.append("document", file);
      return await $api.post(process.env.REACT_APP_API_URI + '/api/import/document', body)
      .then((res) =>res.data)
  }

  const [founders, setFounders] = useState([{}]);

  const addFounder = () =>{
    const newFounders = [
      ...founders,
      {

      }
    ]
    setFounders(newFounders)
  }
  const removeFounder = (index)=>{
    setFounders(founders.filter((item, i )=> i !==index))
  }

  const editFounder = (index, key, value) => {
    setFounders(founders.map((item, i) => {
      if (i === index) {
        item[key] = value
      }
      return item
    }))
  }

  const  handleAddApplication = async (e) => {
    e.preventDefault();

    let fileName = null
    if (file) {
        try {
            fileName = await uploadFile()
        } catch (e) {
        }
    }

    const newApplication = {
              founders: founders,
              documents: [fileName],
              type: 1,
    };

    dispatch(updateApplication(newApplication));

      api.put(`/applications/${id}`, {
        ...application,
        ...newApplication,
        levelEx: parseInt(e.target.levelEx.value.replace(',', '')),
        describeManagement: e.target.describeManagement.value,
        numberOfEngineers: parseInt(e.target.numberOfEngineers.value.replace(',', '')),
        numberOfCEOExists: parseInt(e.target.numberOfCEOExists.value.replace(',', '')),
      })
      .then((response) => {

          api.get(`/applications/${response.id}/score`, ).then(()=>{
              setIsOpenModal(true)
              setScoreId(response.id)
              setUser(response?.user?.investorCompany)
          })
      })
      .catch((error) => {
      })
  }

  const viewScore = () => {
     !user?navigate(`/unlicensed-score/${scoreId}`):navigate(`/licensed-score/${scoreId}`)
  }

  return (
    <Application>
          <div className="Part-container">
          <div className="pageName">
                <h1 className="headerName">Application</h1>
                  <Stepper className="steps" activeStep={2} alternativeLabel>
                     {steps?.map((i,key) => (
                      <Step
                      onClick={()=>navigate(i.url)}
                      sx={{
                        '& .MuiStepLabel-root .Mui-active': {
                        color: '#0F1E38', // circle color (ACTIVE)
                        },
                        '& .MuiStepLabel-root .Mui-completed': {
                          color: '#5CB670', // circle color (COMPLETED)
                        },
                        cursor:'pointer'
                                        }}
                      key={key}>
                   <StepLabel>{i.label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
      <div className="filds-container-3">
          
          <form className="form" onSubmit={handleAddApplication}>
          <h2 className="founders-title">Founders</h2>
          {founders.map((i,index) => (
            <>
            <div className="third-inputs-wrapper">
            <div className="third-left-part">
            {index > 0 ? <DeleteFounder value={index + 1} key={index} onClick={() => removeFounder(index)} /> : null}
            <label className="lable-for-apk">
            {/* <h4 className="ceo">is Ceo? <input onChange={(e) =>editFounder(index, 'isCeo', !i?.isCeo)} defaultChecked={i?.isCeo} className="isCeo" name="isCeo" type="checkbox"></input></h4> */}
              <span className="xs">First Name</span>
              <input className="in" onChange={(e) => editFounder(index, 'firstName', e.target.value)} type="text" name="firstName" required />
            </label>
            <label className="lable-for-apk">
            <span className="xs">Last Name</span>
            <input className="in" onChange={(e) => editFounder(index, 'lastName', e.target.value)} name="lastName" required />
          </label>
            <label className="lable-for-apk">
            <span className="xs">Email</span>
            <input  className="in" onChange={(e) => editFounder(index, 'email', e.target.value)} type="email" name="email" required />
          </label>
          </div>
          <div className="third-right-part">
          <label onMouseOut={() => setFocus1(false)} onMouseOver={() => setFocus1(true)} className="lable-for-apk">
                  {focus1 ? <div className="help-text-wrapper">
                <img src={info} width={20}/>
                <span className="help-text">The founder's LinkedIn Profile Link. </span></div> : null}
            <span className="xs">Linkedin URL</span>
            <input onBlur={(e) => checkValidUrl(e)} className={!isValidUrl ? 'error' : 'in'} onChange={(e) => editFounder(index, 'linkedinUrl', e.target.value)}  type="text" name="linkedinUrl" required />
            {!isValidUrl ? <span className="errorMessage" color="red">Value must be a URL</span> : null}
          </label>
          {/* <label className="lable-for-apk">
            <span className="xs">Scientific experience</span>
            <input className="in" onChange={(e) => editFounder(index, 'scientificExperience', e.target.value)} type="number" min={0} name="scientificExperience" required />
          </label> */}
          <Founders onClick={addFounder} />
          </div>
          </div>
            </>
          ))}
          <div className="finished">
        
            <div className="ss">
           
            <div className="end-part-wrapper">
            <div className="end-part-first">
            <label onMouseOut={() => setFocus2(false)} onMouseOver={() => setFocus2(true)} className="lable-for-apk">
                  {focus2 ? <div className="help-text-wrapper">
                <img src={info} width={20}/><span className="help-text">The number of specialists you have in your company.</span></div> : null}
            <span className="xs">Number Of Engineers</span>
            <NumericFormat thousandSeparator="," className="in" min={0} name="numberOfEngineers" required />
          </label>
          <label onMouseOut={() => setFocus4(false)} onMouseOver={() => setFocus4(true)} className="lable-for-apk">
                  {focus4 ? <div className="help-text-wrapper">
                <img src={info} width={20}/>
                <span className="help-text">An exit strategy is a business owner's strategic plan to sell ownership of a company to investors or another company.</span></div> : null}
            <span className="xs">Number of CEO Exits</span>
            <NumericFormat thousandSeparator="," className="in" min={0} name="numberOfCEOExists" required />
          </label>
          <label onMouseOut={() => setFocus3(false)} onMouseOver={() => setFocus3(true)} className="lable-for-apk">
                  {focus3 ? <div className="help-text-wrapper">
                <img src={info} width={20}/>
                <span className="help-text">The years of experience you have as a Chief Executive Officer. </span></div> : null}
            <span className="xs">CEO C Level Experience</span>
            <NumericFormat thousandSeparator="," className="in" min={0} name="levelEx" required />
          </label>

          {/* <div className="file-input-btn">
          <label htmlFor="file-input" className="lable-for-apk">
            <input
              id="file-input"
              type="file"
              name="documnets"
              accept=".pdf"
              onChange={(e)=>setFile(e.target.files[0])}
              style={{ display: "none" }}
            />
               <img src={ChoseFilr} className="ChoseFilr" style={{cursor:"pointer", width:'40%'}}/>
               </label>
            </div> */}
          </div>
          <div className="end-part-second">
          <label onMouseOut={() => setFocus5(false)} onMouseOver={() => setFocus5(true)} className="lable-for-apk">
                  {focus5 ? <div className="help-text-wrapper">
                <img src={info} width={20}/><span className="help-text">Please tell us in 100 words or less about your staff's positive qualities and skills. </span></div> : null}
            <span className="xs">Describe your Management Team Strength</span>
            <textarea rows={15} className="in-area" type="text" name="describeManagement" required />
          </label>
          </div>
          </div>
            </div>
          </div>
          <div className="btn-next-2"> 
        <button disabled={isValidUrl ? false : true}  type="submit" className="next-btn">
          Next
          </button>
        </div>
        </form>
         
      </div> 
    </div>
    {isOpenModal? <div className="overlead">
      <div className="finish-wrapper">
            <img onClick={()=> setIsOpenModal(!isOpenModal)} className="close-button" src={close} width={20} height={20}></img>
            <div className="wrapper-center">
            <img src={Hello} width={70} height={70}></img>
            <h1 className="congratulations">Congratulations</h1>
            <p className="finish-text">You finished your application</p>
            <div className="wrapper-end">
              <h2 className="question-text">What's Next?</h2>
              <button onClick={viewScore} className="score-view-button">View Score</button>
            </div>
            </div>
      </div>
    </div> : null}
 </Application>
  );
};

export default Thirdpart;