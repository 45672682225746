import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import "./Applications.css"
import {setId} from "./../../State/slice"
import {useDispatch} from "react-redux";
import { useNavigate } from 'react-router-dom';
import lock from '../../img/lock.png'
import unlock from '../../img/unlock.png'
import { toast } from 'react-toastify';
import axios from 'axios'
import { AUTH_API_PATH } from '../../Pages/auth/apiPath';
import { getActivationToken } from '../../utils/storage';
import inst from '../../Pages/Application/api';


function Applications(props) {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const token = getActivationToken()

  const id = props.id

  const viewScore = () => {
    props.paymentsStatus != 2 && !props.user? navigate(`/unlicensed-score/${id}`) :
    navigate(`/licensed-score/${id}`)
    dispatch(setId(id))
  }
  const onCont = () => {
      localStorage.setItem('appID',id)
      if(!props.plannedRevenueInYear){
        return`/application/step2/${id}`
      }else{
        return`/application/step3/${id}`
      }
  }
  const purchaseLicense = () => {
    inst.post(AUTH_API_PATH + "/payments", {
      id: parseInt(id),
    }).then((e) => {
      console.log(e.data)
      window.location.replace(e.data);
    }).catch(() => {
      toast.error("Failed")
    })
  }
  
  return (
    <div onDoubleClick={viewScore} className={props.paymentsStatus != 2 && !props.user?'containerforapk' : 'containerforapk-success'}>
      <div className='application-icon'>
      <img src={props.paymentsStatus != 2 && !props.user? lock : unlock} width={20}/>
      <h1 className='startup-names'>{props.startupName}</h1>
      </div>
      <div className='middle-div-reg'>
      <div className='middle-div'>
      <p className='date'>{props.createdAt}</p>
      {props.paymentsStatus == 2 && props.user?<div className='slash'></div> : null}
          {props.score ? (<div className='score-total'>
          <span className='date'>Score</span>
          <p> {props.score}/500</p></div>): null}
      </div>
      </div>
      <div className='application-info'>
    {/* {!props.plannedRevenueInYear||!props.founders?<img onClick={onCont} className='continiu-btn' src={continu} alt='cont'/>:null} */}
          <Link >
          {props.paymentsStatus != 2 && !props.user? <button className='purchase-button' onClick={purchaseLicense}>Purchase</button> : null}
      </Link>
      <Link to={!props.plannedRevenueInYear||!props.founders?onCont():props.paymentsStatus != 2 && !props.user? `/unlicensed-score/${id}` : `/licensed-score/${id}`}>
        <button className='view-button' >View</button> 
      </Link>
      </div>
    </div>
  );
}

export default Applications;