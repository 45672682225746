import Application from "./Pages/Application/Application";
import Login from "./Pages/auth/Login";
import Register from "./Pages/auth/Register";
import Score from "./Pages/Score/Score";
import { BrowserRouter as Router, Routes, Route,useNavigate } from "react-router-dom";
import "./app.css";
import { ToastContainer } from "react-toastify";
import { UserContext } from "./context/UserContext";
import React, { useEffect, useState } from "react";


import { publicRoutes, privateRoutes } from "./routes";

function App() {
 const [status,setStatus] = useState('')
  const [user, setUser] = useState(null);
  const [login, setLogin] = useState(true);

  useEffect(() => {
    const user = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;
    const access_token = localStorage.getItem("access_token");
        if(access_token){
            setLogin(true)
        } else{
            setLogin(false)
        }
    const userInfo = user
      ? {
          ...user,
          token: access_token,
        }
      : null;
      
    setUser(userInfo);
    setUser(user);
  }, []);
  const loginUser = ({data,status}) => {
    setStatus(data)
    localStorage.setItem("user", JSON.stringify(data.user));
    localStorage.setItem("access_token", data.access_token);
    setUser(data.user);
  };

  const isMobile = window.innerWidth < 1070 && user
 
  return (
    <UserContext.Provider className="app" value={{ user, setUser: loginUser, }}>  
{/* {isMobile && user != null ? <div className="noMobile"><h3 className="noMobileMessage">Our mobile version is coming soon. Please continue on desktop.</h3></div> : */}
      <Router >   
        <Routes>
            {privateRoutes.map((route,key) => (
           <Route key={key} path={route.path} element={route.element}  />
          ))}
          {publicRoutes.map((route,key) => (
            <>
            <Route key={key} path={route.path} element={route.element} />
              </>
          ))}
          </Routes>
      </Router>
      {/* } */}
      <ToastContainer/>
    </UserContext.Provider>
  );
}

export default App;