import * as React from 'react';
import { useContext, useEffect, useState } from 'react' ;
import { Link } from 'react-router-dom';
import "./auth.css"
import logo from "./../../img/logo.png"
import OtpInput from 'react-otp-input';
import { useParams } from 'react-router-dom';
import btn from "./../../img/linkdin.png"
import { Checkbox } from '@material-ui/core';
import axios from 'axios'
import { toast } from 'react-toastify'
import { useNavigate, } from 'react-router-dom'
import { UserContext } from '../../context/UserContext'
import { AUTH_API_PATH } from "./apiPath";
import api from '../../utils/api'

const OtpcheckRegister = () => {

  const [code, setCode] = useState('');
  const {email} = useParams()
  const navigate = useNavigate()
  function handleSubmit(event) {
    event.preventDefault();
    if(code){
        axios.post(AUTH_API_PATH+"/activate", {
            email,
            code:+code
        }).then((e) => {
            navigate('/')
        }).catch(() => {
            toast.error("Error !");
        })
    }

  }

    return (  
      <div className='auth-container'>
        <div className='header-forLogin'>
          <img src={logo} alt='logo' className='logo-for-login'/>
          {/* <div className='vector_1'></div>
          <div className='titles-wrapper'>
            <h1>WORLDWIDE INNOVATIONS</h1>
            <h5>IP Protection & Acquisition. Financing</h5>
          </div> */}
        </div>
        <div className='login-container'>
          <div className='pading'>
            <div className='names'>
                <h1 className='headerName'>WE'VE SENT A CODE TO YOUR EMAIL</h1>
              <h2 className='h2'>Orion Score</h2>
            </div>
      <form className='Forgotwrap' onSubmit={handleSubmit}>
        <div className='otp'>
        <OtpInput
             inputStyle={{height:"40px",width:'40px',borderRadius:'5px',fontSize:"15px",}}
             containerStyle={{height:"100px",width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}
             value={code}
             onChange={setCode}
             numInputs={5}
             renderSeparator={<span>-</span>}
             renderInput={(props) => <input {...props} />}
    />
        </div>
   
        <div className='buttons' >  
          <button className='login-btn' type="submit">Send</button>
       </div>
       
      </form>
      </div>
        </div>
   </div> 
  );
}
 
export default OtpcheckRegister;