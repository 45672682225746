import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id:0,
  applications:[],
  application:{
    startupName: "",
    yearFounded: "",
    website: "",
    linkedin: "",
    demoVideoUrl: "",
    industry: 1,
    businessModel: 1,
    ipRegistered: 1,

    plannedRevenueInYear: "",
    plannedRevenueInThreeYear: "",
    fundsReceived: 1,
    runawayMonths: 2,
    desiredAmountToRaise: "",
    founders: [
      {
        name: "",
        email: "",
        linkedinUrl: "",
        documents: "",
      },
    ],
    type: 1,
  },
  currentScore: null
}

export const appReducer = createSlice({
  name: "appData",
  initialState,
  reducers: {
    updateApplication:  (state, action) => {
      state.application = {
        ...state.application,
        ...action.payload
      }
    },
    setApplications:  (state, action) => {
      state.applications = action.payload
    },
    setId:(state,action)=>{ state.id = action.payload},
    setScore: (state, action) => {
      state.currentScore = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateApplication, setScore, setApplications,setId } = appReducer.actions;

export default appReducer.reducer;