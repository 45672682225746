import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import vector1 from "./../img/Vector1.png"
import Application from '../Pages/Application/Application';
import { useSelector} from "react-redux";
import { useState,useEffect } from 'react';
import { $api } from '../api';
import { useParams } from 'react-router-dom';

function Allsteps() {
  const { id } = useParams();
  
 const [currentApplication,setCurrentApplication]=useState([]) 
 useEffect(()=>{
  $api.get(process.env.REACT_APP_API_URI + `/api/applications/${id}`)
      .then(response => {
        setCurrentApplication(response.data)
      })
      .catch(error => {
        console.error(error);
      });
},[])


    return (
    <Application>
      <div className='contoler'>
      <div className='navigationto1'> 
      <Link className='link-nav' to="/applications">
      <img src={vector1}/>
       <h1 className='nav-name'>Back to applications</h1>
      </Link>

      </div>
  
      </div>
    </Application>
  );
}

export default Allsteps;
