import * as React from 'react';
import { useContext, useEffect, useState } from 'react' ;
import { Link } from 'react-router-dom';
import "./auth.css"
import logo from "./../../img/logo.png"
import btn from "./../../img/linkdin.png"
import { Checkbox } from '@material-ui/core';
import axios from 'axios'
import { toast } from 'react-toastify'
import { useNavigate, } from 'react-router-dom'
import { UserContext } from '../../context/UserContext'
import { AUTH_API_PATH } from "./apiPath";
import api from '../../utils/api'

const ForgotePassword = () => {
  const [email, setEmail] = useState('');

//   const { setUser, user } = useContext(UserContext);

  const navigate = useNavigate()

  function handleSubmit(event) {
    event.preventDefault();
    if(email){
        axios.post(AUTH_API_PATH+"/forgot-password", {
            email,
        }).then((e) => {
            navigate(`/otpCheck/${email}`)
        }).catch(() => {
        })
    }
  }

    return (  
      <div className='auth-container'>
        <div className='header-forLogin'>
          <img src={logo} alt='logo' className='logo-for-login'/>
          {/* <div className='vector_1'></div>
          <div className='titles-wrapper'>
            <h1>WORLDWIDE INNOVATIONS</h1>
            <h5>IP Protection & Acquisition. Financing</h5>
          </div> */}
        </div>
        <div className='login-container'>
          <div className='pading'>
            <div className='names'>
              <h1 className='headerName'>Forgote Password</h1>
              <h2 className='h2'>Orion Score</h2>
            </div>
      <form className='Forgotwrap' onSubmit={handleSubmit}>
        <label className='email-l'>
        <span className='style-email'>Email address</span>
          <div className='regul-inputs'>
          <input className='email-input' type="email" value={email} onChange={(e)=>setEmail(e.target.value)} />
          </div>
        </label>
        <div className='buttons' >  
          <button className='login-btn' type="submit">Reset</button>
       </div>
       
      </form>
      </div>
        </div>
   </div> 
  );
}
 
export default ForgotePassword;