import React, { useEffect } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useState } from "react";
import Application from "../../Pages/Application/Application";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./Parts.css"
import { toast } from 'react-toastify';
import api from '../../utils/api'
import * as Validator from '../../utils/validation'
import TextField from '@mui/material/TextField';
import { Autocomplete } from "@mui/material";
import { createFilterOptions } from "@mui/material";
import { years, industries, businessModels } from "../../Arrays/arrays";
import info from "../../img/info.png"



const cities = require("cities-list");

let citiesObject = Object.keys(cities);

function FirstPart() {

  const { id } = useParams()
  const [application, setApplications] = useState(null)
  const [check1, setCheck1] = useState(0)
  const [check2, setCheck2] = useState(0)
  useEffect(() => {
    if (+id !== 0) {
      api.get(`applications/${id}`)
        .then(response => {
          setApplications(response)
          setCheck1(response.ipRegistered)
          setCheck2(response.incorporated)
          setHqLocationValue(response.hqLocationName)
          setYearFounded(response.yearFounded)
          setValueIndustry(response.industry)
          setValueBuisnes(response.businessModel)
        })
        .catch(error => {
          console.error(error);
        });
    }

  }, [])
  const steps = window.innerWidth >= 760 ? [{ label: "Step 1", url: `/application/step1/${id}` }, { label: "Step 2", url: `/application/step2/${id}` }, { label: "Step 3", url: `/application/step3/${id}` }] : [{ label: " ", url: `/application/step1/${id}` }, { label: " ", url: `/application/step2/${id}` }, { label: " ", url: `/application/step3/${id}` }];
  const [valueIndustry, setValueIndustry] = useState('')
  const [valueBuisnes, setValueBuisnes] = useState('')
  const [isValidUrl1, setIsValidUrl1] = useState(true)
  const [isValidUrl2, setIsValidUrl2] = useState(true)
  const [isValidUrl3, setIsValidUrl3] = useState(true)
  const [isValidUrl4, setIsValidUrl4] = useState(true)
  const [isValidUrl5, setIsValidUrl5] = useState(true)
  const [focus1, setFocus1] = useState(false)
  const [focus2, setFocus2] = useState(false)
  const [focus3, setFocus3] = useState(false)
  const [focus4, setFocus4] = useState(false)
  const [hqLocationValue, setHqLocationValue] = useState("")
  const [yearFounded, setYearFounded] = useState('')
  const [countries, setCountries] = useState([])

  const checkValidUrl1 = (e) =>
    setIsValidUrl1(Validator.isValidUrl(e.target.value));

  const checkValidUrl2 = (e) =>
    setIsValidUrl2(Validator.isValidUrl(e.target.value));

  const checkValidUrl3 = (e) =>
    setIsValidUrl3(Validator.isValidUrl(e.target.value));

  const checkValidUrl4 = (e) =>
    setIsValidUrl4(Validator.isValidUrl(e.target.value));

  const checkValidUrl5 = (e) =>
    setIsValidUrl5(Validator.isValidUrl(e.target.value));

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const searchItems = (e) => {
    setHqLocationValue(e.target.outerText)
  }

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAddApplication = (e) => {
    e.preventDefault();
    const newApplication = {
      startupName: e.target.startupName.value,
      yearFounded: e.target.yearFounded.value,
      productDescription: e.target.productDescription.value,
      website: e.target.website.value,
      linkedin: e.target.linkedin.value,
      demoVideoUrl: e.target.demoVideoUrl.value,
      crunchBaseUrl: e.target.crunchBaseUrl.value,
      pitchUrl: e.target.pitchUrl.value,
      industry: valueIndustry,
      businessModel: valueBuisnes,
      hqLocationName: hqLocationValue,
      ipRegistered: check1,
      incorporated: check2,
      ein: e.target.ein.value
    };
    if (!+id) {
      api.post("/applications", newApplication).then((response) => {
        navigate(`/application/step2/${response?.id}`);

      }).catch(e => {
        console.log(e)
      })
    }
    api.put(`/applications/${id}`, newApplication).then((response) => {
      navigate(`/application/step2/${response?.id}`);
    }).catch(e => {
      console.log(e)
    })


  };
  const inputYear = (e) => {
    setYearFounded(e.target.innerText)
    setFocus3(false)
  }
  const OPTIONS_LIMIT = 100;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  const handleSelect = (event, value) => {
    setValueBuisnes(value?.label.toLowerCase());
  };

  return (
    <Application>
      <div className="Part-container">
        <div className="pageName">
          <h1 className="headerName">Application</h1>
          <Stepper nonLinear className="steps" activeStep={0} alternativeLabel>
            {steps?.map((i, key) => (
              <Step
                onClick={() => navigate(i.url)}
                sx={{
                  '& .MuiStepLabel-root .Mui-active': {
                    color: '#0F1E38',
                  },
                }}
                key={key}>
                <StepLabel>{i.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <div className="filds-container">
          <form className="form" onSubmit={handleAddApplication}>
            <div className="inputs-wrapper">
              <div className="left-part">
                <label className="lable-for-apk">
                  <span className="xs">Startup name</span>
                  <input defaultValue={application?.startupName} className="in" type="text" name="startupName" required />
                </label>
                <label className="lable-for-apk">
                  <span className="xs">Product Description</span>
                  <textarea defaultValue={application?.productDescription} className="in-area" name="productDescription" rows='9'></textarea>
                </label>
                <label className="lable-for-apk-hq">
                  <span className="xs">HQ City Location</span>
                  <Autocomplete value={hqLocationValue} onChange={searchItems} sx={{ boxShadow: 0 }} className="inM" name='hqLocationName' id="hqLocationName" required
                    size="small"
                    options={citiesObject}
                    getOptionLabel={(option) => option}
                    filterOptions={filterOptions}
                    renderInput={(params) => <TextField value={hqLocationValue} {...params} />}
                  />
                </label>
                <label onMouseOut={() => setFocus3(false)} onMouseOver={() => setFocus3(true)} className="lable-for-apk">
                  {focus3 ? <div className="help-text-wrapper">
                    <img src={info} width={20} />
                    <span className="help-text">The date when your company was formed or business started.</span></div> : null}
                  <span className="xs">Year Founded</span>
                  <Autocomplete value={yearFounded} onChange={inputYear} className="inM" id='yearFounded' name='yearFounded' required
                    size="small"
                    options={years}
                    renderInput={(params) => <TextField value={yearFounded} {...params} />}
                  />
                </label>
                <label className="lable-for-apk">
                  <span className="xs">Industry</span>
                  <Autocomplete value={valueIndustry ? industries.find((i) => i.id === valueIndustry)?.label : ''} className="inM" id="industry" name="industry" required
                    size="small"
                    onChange={(event, value) => setValueIndustry(value?.id)}
                    options={industries}
                    renderInput={(params) => <TextField value={industries.find((i) => i.id == valueIndustry)?.label} {...params} />}
                  />
                </label>
                <label onMouseOut={() => setFocus1(false)} onMouseOver={() => setFocus1(true)} className="lable-check">
                  {focus1 ? <div className="help-text-wrapper">
                    <img src={info} width={20} /><span className="help-text">Registered IP means all Intellectual Property Rights that are registered, filed, or issued under the authority of any Governmental Body.</span></div> : null}
                  <span className="xs">IP Registered</span>
                  <div className="rs spospo">
                    <div >
                      <input checked={check1 == 1} onChange={(e) => setCheck1(e.target.value)} className="radio" name="ipRegisteredD" id="a" type="radio" required value={1} />
                      <label htmlFor="a">Yes</label>
                    </div>
                    <div style={{ marginLeft: '50px' }}>
                      <input checked={check1 == 2} onChange={(e) => setCheck1(e.target.value)} className="radio" name="ipRegisteredD" id="b" type="radio" required value={2} />
                      <label htmlFor="b">No</label>
                    </div>
                  </div>
                </label>
                <label onMouseOut={() => setFocus2(false)} onMouseOver={() => setFocus2(true)} className="lable-check">
                  {focus2 ? <div className="help-text-wrapper">
                  <img src={info} width={20} />
                  <span className="help-text">That is, whether your business is registered with a state so that it becomes a separate legal entity.</span></div> : null}
                  <span className="xs">Company Incorporated?</span>
                  <div className="rs spo">
                    <div >
                      <input checked={check2 == 1} onChange={() => setCheck2(1)} className="radio" name="incorporatedD" id="c" type="radio" required value={1} />
                      <label htmlFor="c">Yes</label>
                    </div>
                    <div style={{ marginLeft: '50px' }}>
                      <input checked={check2 == 2} onChange={() => setCheck2(2)} className="radio" name="incorporatedD" id="d" type="radio" required value={2} />
                      <label htmlFor="d">No</label>
                    </div>
                  </div>
                </label>
                <label className="lable-for-apk">
                  <span className="xs">EIN (Optional)</span>
                  <input defaultValue={application?.ein} className="in" type="text" name="ein" />
                </label>
              </div>
              <div className="right-part">
                <label className="lable-for-apk">
                  <span className="xs">Business Model</span>
                  <Autocomplete className="inM" id="businessModel" name="businessModel" required
                    size="small"
                    value={valueBuisnes}
                    onChange={handleSelect}
                    options={businessModels}
                    renderInput={(params) => <TextField variant="outlined" value={valueBuisnes} {...params} />}
                  />
                </label>
                <label className="lable-for-apk">
                  <span className="xs">Demo Video URL (Optional)</span>
                  <input defaultValue={application?.demoVideoUrl} onChange={(e) => checkValidUrl1(e)} className={!isValidUrl1 ? 'error' : 'in'} type="text" name="demoVideoUrl" />
                  {!isValidUrl1 ? <span className="errorMessage" color="red">Value must be a URL</span> : null}
                </label>
                <label onMouseOut={() => setFocus4(false)} onMouseOver={() => setFocus4(true)} className="lable-for-apk">
                  {focus4 ? <div className="help-text-wrapper">
                    <img src={info} width={20} />
                    <span className="help-text">The founder's LinkedIn Profile Link. </span></div> : null}
                  <span className="xs">LinkedIn (Optional)</span>
                  <input defaultValue={application?.linkedin} onChange={(e) => checkValidUrl2(e)} className={!isValidUrl2 ? 'error' : 'in'} id="linkedin" name="linkedin" />
                  {!isValidUrl2 ? <span className="errorMessage" color="red">Value must be a URL</span> : null}
                </label>
                <label className="lable-for-apk">
                  <span className="xs">Website (Optional)</span>
                  <input defaultValue={application?.website} onChange={(e) => checkValidUrl3(e)} className={!isValidUrl3 ? 'error' : 'in'} id="website" name="website" />
                  {!isValidUrl3 ? <span className="errorMessage" color="red">Value must be a URL</span> : null}
                </label>
                <label className="lable-for-apk">
                  <span className="xs">Crunchbase URL (Optional)</span>
                  <input defaultValue={application?.crunchBaseUrl} onChange={(e) => checkValidUrl4(e)} className={!isValidUrl4 ? 'error' : 'in'} type="text" name="crunchBaseUrl" />
                  {!isValidUrl4 ? <span className="errorMessage" color="red">Value must be a URL</span> : null}
                </label>
                <label className="lable-for-apk">
                  <span className="xs">Pitch URL (Optional)</span>
                  <input defaultValue={application?.pitchUrl} onChange={(e) => checkValidUrl5(e)} className={!isValidUrl5 ? 'error' : 'in'} type="text" name="pitchUrl" />
                  {!isValidUrl5 ? <span className="errorMessage" color="red">Value must be a URL</span> : null}
                </label>
                <label onMouseOut={() => setFocus1(false)} onMouseOver={() => setFocus1(true)} className="lable-check-mobile">
                  {focus1 ? <div className="help-text-wrapper">
                    <img src={info} width={20} />
                    <span className="help-text">Registered IP means all Intellectual Property Rights that are registered, filed, or issued under the authority of any Governmental Body.</span></div> : null}
                  <span className="xs">IP Registered</span>
                  <div className="rs">
                    <div >
                      <input checked={check1 === 1} onChange={(e) => {
                        setCheck1(1)
                      }} className="radio" name="ipRegisteredr" type="radio" value={'1'} />
                      <label htmlFor="a">Yes</label>
                    </div>
                    <div style={{ marginLeft: '50px' }}>
                      <input checked={check1 === 2} onChange={(e) => {
                        setCheck1(2)
                      }} className="radio" name="ipRegisteredr" type="radio" value={'2'} />
                      <label htmlFor="b">No</label>
                    </div>
                  </div>
                </label>
                <label onMouseOut={() => setFocus2(false)} onMouseOver={() => setFocus2(true)} className="lable-check-mobile" >
                  {focus2 ? <div className="help-text-wrapper">
                    <img src={info} width={20} />
                    <span className="help-text">That is, whether your business is registered with a state so that it becomes a separate legal entity.</span></div> : null}
                  <span className="xs">Company Incorporated?</span>
                  <div className="rs">
                    <div >
                      <input onChange={(e) => setCheck2(1)} className="radio" name="incorporated" id="c" type="radio" required value={1} checked={check2 === 1} />
                      <label htmlFor="c">Yes</label>
                    </div>
                    <div style={{ marginLeft: '50px' }}>
                      <input onChange={(e) => setCheck2(2)} className="radio" name="incorporated" id="d" type="radio" required value={2} checked={check2 === 2} />
                      <label htmlFor="d">No</label>
                    </div>
                  </div>
                </label>
              </div>
            </div>
            <div className="btn-next">
              <button disabled={!(isValidUrl1 && isValidUrl2 || isValidUrl1 == '' && isValidUrl2 == '')} type="submit" className="next-btn">
                Next
              </button>
            </div>

          </form>

        </div>
      </div>
    </Application >
  );
}

export default FirstPart;