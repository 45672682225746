export const years = [
    "2023",
    "2022",
    "2021",
    "2020",
    "2019",
    "2018",
    "2017",
    "2016",
    "2015",
    "2014",
    "2013",
    "2012",
    "2011",
    "2010",
    "2009",
    "2008",
]

export const industries = [
    { label: 'Fintech', id: "1" },
    { label: 'Healthcare', id: "2" },
    { label: 'AI', id: "4"},
    { label: 'Deeptech', id: "5"},
    { label: 'Security', id: "6"},
    { label: 'Data Science', id: "7"},
    { label: 'Other', id: "8"},
]

export const businessModels = [
    { label: 'SaaS', id: "1" },
    { label: 'PaaS', id: "2" },
    { label: 'DaaS', id: "3" },
    { label: 'Other', id: "4" },  
]


export const numbers = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
]