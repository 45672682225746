import Login from "./Pages/auth/Login";
import ResetPassword from "./Pages/auth/ResetPassword";
import ForgotePassword from "./Pages/auth/ForgotePassword";
import Register from "./Pages/auth/Register";
import Score2 from "./components/Score2";
import Score1 from "./components/Score1";
import OtpCheck from "./Pages/auth/OtpCheck";
import Allapk from "./components/Allapk";
import Allscore from "./components/Allscore";
import Allsteps from "./components/Allsteps";
import Firstpaart from "./components/Parts/Firstpaart";
import Secondpart from "./components/Parts/Secondpart";
import Thirdpart from "./components/Parts/Thirdpart";
import Info from "./components/Info/Info";
import TokenStep from "./Pages/auth/TokenStep";
import OtpcheckRegister from "./Pages/auth/OtpcheckRegister";
import {Navigate} from 'react-router-dom';
import Hash from "./components/hashVerify/Hash";


export const publicRoutes = [
    {
        path: "/",
        element: <Login />
    },
    {
        path: "*",
        element: <Login />
    },
    {
        path: "/register",
        element: <Register />
    },

    {
        path: "/forgot",
        element: <ForgotePassword/>
    },
    {
        path: "/otpCheck/:email",
        element: <OtpCheck/>
    },
    {
        path: "/OtpcheckRegister/:email",
        element: <OtpcheckRegister/>
    },
    {
        path: "/reset-password/:email",
        element: <ResetPassword/>
    },
    {
        path: "/activate/:token",
        element: <TokenStep />
    },
    {
        path: "/hashVerify",
        element: <Hash />
    },
];

export const privateRoutes = [
    {
        path: "/scores",
        element: <Allscore />
    },
    {
        path: "/hashVerify",
        element: <Hash />
    },
    {
        path: "/applications",
        element: <Allapk />
    },
    {
        path: "/applications/info/:id",
        element: <Info />
    },
    {
        path: "/application/step1/:id",
        element: <Firstpaart />
    },
    {
        path: "/application/step2/:id",
        element: <Secondpart />
    },
    {
        path: "/application/step3/:id",
        element: <Thirdpart />
    },
    {
        path: "/allSteps",
        element: <Allsteps/>
    },
    {
        path: "/licensed-score/:id",
        element: <Score2 />
    },
    {
        path: "/unlicensed-score/:id",
        element: <Score1 />
    },
    {
        path:"/application/:id",
        element: <Allsteps />
    },
];