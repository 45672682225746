import React from 'react'
import Vevtor from "./../img/Vector 72.png"
import { Link } from 'react-router-dom'
import {setId} from "./../State/slice"
import {useDispatch} from "react-redux";



function Scores(props) {
  const dispatch = useDispatch()
  const id = props.index
  return (
    <div className='containerforapk' >
    <h1 className='startup-name'>Score {id}</h1>
    <Link to= {`/licensed-score/${id}`}state={{ id: id}} >
        <div className='vector' >
        <img className='RectanBtn'src={Vevtor} onClick={()=>dispatch(setId(props.index))} />
        </div>
      </Link>
</div>
  )
}

export default Scores