
  export const isValidUrl = (value) => {
    let re = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    return re.test(value);
  };


  export const isValidPassword = (value) => {
    let re = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=.*?[!@#$%^&*()_+[\]{};':"\\|,.<>?/]).{8,}$/;
    return re.test(value);
  };