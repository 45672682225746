import React, { useState, useEffect } from 'react'
import Score from '../Pages/Score/Score';
import inst from './../Pages/Application/api'
import { useParams } from 'react-router-dom';
import { Chart as ChartJS, ArcElement, plugins } from 'chart.js'
import { Doughnut } from "react-chartjs-2";
import jsPDF from 'jspdf';
import 'react-circular-progressbar/dist/styles.css';
import download from '../img/download.png'
import html2canvas from 'html2canvas';
import { useNavigate } from 'react-router-dom';
import success from '../img/success.png'
import danger from '../img/danger.png'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { SpinnerCircular } from "spinners-react";
import logo from '../img/logo-background.jpg'
import Application from '../Pages/Application/Application';

ChartJS.register(
  ArcElement,
)

function Score2() {

  const navigate = useNavigate()
  let [data, setData] = useState(null);
  let [data1, setData1] = useState(null)
  const [value, setValue] = useState(null)
  const [isPrinting, setIsPrinting] = useState(false)
  const [name, setName] = useState()

  const { id } = useParams();
  const [currentScore, setCurrentScore] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (id) {
      inst.get(process.env.REACT_APP_API_URI + `/api/applications/${id}`)
        .then(response => {
          setCurrentScore(JSON.parse(response.data.dms))
          setName(response.data.startupName)
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [])
  const score = currentScore?.totalScore
  const startColor = window.getComputedStyle(document.body, '').getPropertyValue('--grad-1');
  const endColor = window.getComputedStyle(document.body, '').getPropertyValue('--grad-2');

  const viewInfo = () => {
    navigate(`/applications/info/${id}`)
  }

  useEffect(() => {
    {
      currentScore?.evalutions?.map((i) => {
        return setValue(i.value.toString())
      })
    }
  }, [currentScore])


  const gradient = React.useMemo(() => {
    const ctx = document.createElement('canvas').getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 900);
    const stopPercentage = score >= 250 ? score : 0;
    gradient.addColorStop(0, startColor);
    gradient.addColorStop(stopPercentage / 1000, startColor);
    gradient.addColorStop(stopPercentage / 2500, endColor);
    gradient.addColorStop(1, endColor);
    return gradient;
  }, [score]);

  useEffect(() => {

    setData({
      labels: ['yes', 'no'],
      datasets: [{
        cutout: '65%',
        label: "Poll",
        data: [Math.round(score), (500 - Math.round(score))],
        backgroundColor: [gradient, "#ededed"],
        borderWidth: 0,
        width: '10px'

      }],
    })

    setData1({
      labels: ['yes', 'no'],
      datasets: [{
        cutout: '65%',
        label: "Poll",
        data: [Math.round(value), (100 - Math.round(value))],
        backgroundColor: [gradient, "#ededed"],
        borderWidth: 0,
        width: '10px'

      }],
    })
  }, [score])

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      }
    },
  }
  const textCenter = {
    id: 'textCenter',
    beforeDatasetsDraw(chart) {
      const { ctx, data } = chart;
      ctx.save();
      ctx.font = "bolder 20px sans-serif";
      ctx.fillStyle = "#1998A0";
      ctx.textBaseline = 'middle';
      ctx.fillText(`${Math.round(data.datasets[0].data[0])}`, chart.getDatasetMeta(0).data[0].x - 34, chart.getDatasetMeta(0).data[0].y)
      ctx.fillStyle = "#1998A0";
      ctx.fillText(`/500`, chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y)
    }
  }


  const downLoadPdf = () => {
    setIsPrinting(true)
    setTimeout(()=> {
      const input = document.querySelector('.columnWrapper');
    html2canvas(input)
      .then((canvas) => {
        let imgWidth =canvas.width * (297 / canvas.height);
        let imgHeight = canvas.height * imgWidth / canvas.width;
        const imgData = canvas.toDataURL('img/png');
        const pdf = new jsPDF('p', 'mm', 'a4', true);
        pdf.addImage(imgData, 'PNG', 30, 0, imgWidth, 297);
        window.open(pdf.output('bloburi',{filename:"Orion_Score.pdf"}))
        setIsPrinting(false)
      })
  }, 1000)
  }

  return (
    <Application>
    <Score>
      <div className={isPrinting == true ? 'contoler-score-print' :'contoler-score'}>
        <div className='columnWrapper'>
      {isPrinting == true ?<div className='logo'><img src={logo} width={200}/>
      </div> : null}
          <div className='topPart'>
            {isPrinting == false && window.innerWidth>850?<div onClick={downLoadPdf} className='score-title'>
              <img src={download} width={25} height={25} style={{ paddingLeft: "25px", cursor: 'pointer' }}></img>
            </div> : null}
            <div className='title-wrapper'>
              <h3 className='title-h3'>{name ? name : "Startup Name"} Orion Score Report</h3>
              <p className='title-p'>{currentScore?.genDescription}</p>
            </div>
            <div id='forPrint' className='filds-container-score'>

              <div className='char-container'>
                <div className='cricle'>
                  {data ? (<Doughnut
                    style={{ height: '200px', width: "200px", margin: '15px' }}
                    data={data}
                    options={options}
                    plugins={[textCenter]}
                  />) : null}

                </div>
              </div>
            </div>
          </div>
          <div className={isPrinting == true ? 'componentsWrapper-print' : 'componentsWrapper'}>
            {currentScore?.evalutions?.map((i, key) => (
              <div key={key} className={isPrinting == true ? 'info-wrapper1-print' : 'info-wrapper1'}>
                <div className='nameAndRanges'>
                  <div><h3 className={isPrinting == true ? 'title-print':'title'}>{i.title}</h3></div>
                  <div className='Application-color'>
                    <h3 className={isPrinting == true ? 'text-assessment-print':'text-assessment'}>{i.assessment}</h3>
                    <div className=
                    {isPrinting == true ? 'chartText-wrapper-print' : 'chartText-wrapper'}>{currentScore ? (
                      <><CircularProgressbar sx={{ width: "10%" }} value={Math.round(i.value)}
                        styles={buildStyles({
                          textSize: '20px',
                          pathColor: `${i.value < 45 ? 'red' : i.value > 45 && i.value < 75 ? 'orange' : 'green'}`,
                          textColor: `${i.value < 45 ? 'red' : i.value > 45 && i.value < 75 ? 'orange' : 'green'}`,
                          backgroundColor: 'red',
                        })}
                        text={Math.round(i.value) + " %"} /></>) : null}
                      <h4 className='sectionDesc'>{i.sectionDesc}</h4>
                    </div>
                  </div>
                </div>
                <div className={isPrinting == true ? 'massages-print' : 'massages'}>
                {i?.positiveMessages.length ?
                    <div className='icons'>
                      <img src={success} width={15} />
                      <div><h3 className={isPrinting == true ? 'text-title-print' : 'text-title'}>Things that you are great at.</h3>
                        {i?.positiveMessages.map((message, uid) => {
                          return <li key={uid} className={isPrinting == true ? 'text-print' : 'text'}>{message}</li>
                        })}
                      </div>
                    </div>
                    : null}
                  {i?.negativeMessages.length ?
                    <div className='icons'>
                      <img src={danger} width={15} />
                      <div><h3 className={isPrinting == true ? 'text-title-print' : 'text-title'}>Things to focus on to make you more competitive.</h3>
                        {i?.negativeMessages.map((message, uid) => {
                          return <li key={uid} className={isPrinting == true ? 'text-print' : 'text'}>{message}</li>
                        })}
                      </div>
                    </div>
                    : null}
                </div>
              </div>
            ))}

          </div>
          {isPrinting == false ? <a onClick={viewInfo} className='view-data'>View Submitted Data</a> : null}
        </div>
      </div>
      {isPrinting && <div className='printing'>
        <SpinnerCircular sx={{margin: '20px'}}
                                size="200"
                                thickness="200"
                                color="#1998A0"
                                secondaryColor="silver" />
      </div>
      }
    </Score>
    </Application>
  )
}

export default Score2