import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import { useContext } from 'react';
import logout from "./../img/loguut.png"
import { Link } from 'react-router-dom';

function Logout() {
    const [user,setUser] = useState(true)
    const resetUser = () => {
        setUser(false);
        localStorage.removeItem("user");
        localStorage.removeItem("access_token");
      };
    const navigate = useNavigate()

  return (
    <Link className='forNavigate' to='/'>
      <div className='logout-wrapper'>
         <img style={{color: 'white'}} src={logout} width={30} className='logout' onClick={resetUser}/>
         <div className='links-log'>Log Out</div>
    </div>
    </Link>
  )
}

export default Logout