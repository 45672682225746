import React from 'react'
import cc from "./../img/create.png"
import Score from '../Pages/Score/Score'
import { Link } from 'react-router-dom'
import Scores from './Scores'
import { AUTH_API_PATH } from '../Pages/auth/apiPath'
import { useEffect } from 'react'
import { useState } from 'react'
import { $api } from '../api'


function Allscore() {

  const [data, setData] = useState([]);
  useEffect(() => {
    $api.get(AUTH_API_PATH + '/applications/me')
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);
  return (

    <Score>
      <div className='Allapk'>
        <div className='header1'>
          <div className='petqa123'>
            <h1 className='startup-name'>Scores</h1>
            <Link to="/application/step1">
              <button className='create-button'>+ Add Score</button>
            </Link>
          </div>
        </div>
        <div className='containerforcollection'>

          {data.map((i, index) => (<Scores data={data} key={index} index={i.id} />))}

        </div>
      </div>
    </Score>
  )
}

export default Allscore