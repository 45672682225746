function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }
  
  export function formatDate(date) {
  
    return (
      [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.toLocaleString('en-US', {
          month: 'long'
        })),
        date.getFullYear(),
      ].join(" ")
      
    );
  }
  