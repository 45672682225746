import React from "react";
import { SpinnerCircular } from "spinners-react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { $api } from "../../api"
import {AUTH_API_PATH} from "./apiPath"

export default function TokenStep () {

    let { token } = useParams();

    const navigate = useNavigate()

    useEffect(() => {
        $api.post(AUTH_API_PATH+`/activate/`, {activationToken: token})
        .then((e) => {
            navigate('/')
          })
          .catch(error => {
            navigate('/register')
            console.error(error);
          });
      }, []);

    return(
        <div className="spinner">
        <SpinnerCircular sx={{margin: '20px'}}
                                size="120"
                                thickness="200"
                                color="#143495"
                                secondaryColor="silver" />
                                </div>
    )
}