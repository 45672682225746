import React, { useEffect } from "react";
import { useState } from "react";
import './Info.css';
import { useParams } from "react-router-dom";
import { AUTH_API_PATH } from "../../Pages/auth/apiPath";
import { $api } from "../../api";
import Application from "../../Pages/Application/Application";
import { businessModels } from "../../Arrays/arrays";
import { NumericFormat } from 'react-number-format';
import { numbers } from "../../Arrays/arrays";
import TextField from '@mui/material/TextField';
import { Autocomplete } from "@mui/material";

function Info () {
  const [data,setData] = useState(null)
  const {id} = useParams()
  
  const bussinessModels = [
    {
      value: 'SaaS',
      key: 'saas'
    },
    {
      value: 'PaaS',
      key: 'paas'
    },
    {
      value: 'DaaS',
      key: 'daas'
    },
    {
      value: 'Other',
      key: 'other'
    }
  ]
 
  useEffect(()=>{
    $api.get(AUTH_API_PATH+`/applications/${id}`)
    .then(({data})=>setData(data))
    .catch(error => {
      console.error(error);
    });
  },[])


    return(
        <Application>
    <div className="Part-container">
    <div className="pageName">
                <h1 className="headerName">Application</h1>
          </div>
      <div className="filds-container">
          <form className="form">
            <div className="inputs-wrapper">
            <div className="left-part">
          <label className="lable-for-apk">
            <span className="xs">Startup Name</span>
            <input disabled value={data?.startupName} className="in" type="text" name="startupName" required />
          </label>
          <label className="lable-for-apk">
            <span className="xs">Product Description</span>
            <textarea disabled value={data?.productDescription} className="in-area" rows='6'></textarea>
          </label>
          <label className="lable-for-apk">
            <span className="xs">HQ City Location</span>
            <select disabled value={data?.hqLocationName} className="in" id="hqLocationName" name="hqLocationName" required >
              <option value={0}>{data?.hqLocationName}</option>
            </select>
          </label>
          <label className="lable-for-apk">
            <span className="xs">Demo Video URL</span>
            <input disabled value={data?.demoVideoUrl} className='in' type="text" name="demoVideoUrl" />
          </label>
          <label className="lable-for-apk">
            <span className="xs">Pitch URL</span>
            <input disabled value={data?.pitchUrl} className='in' type="text" name="pitchUrl" />
          </label>
          <label className="lable-for-apk">
            <span className="xs">Crunchbase URL</span>
            <input disabled value={data?.crunchBaseUrl} className='in' type="text" name="crunchBaseUrl" />
          </label>
          <label className="lable-for-apk">
            <span className="xs">IP Registered</span>
            <div className="rs">
            <div >
            <input  name="ipRegistered" id="a" type="radio" required value={1} checked={data?.ipRegistered==1} disabled/>
              <label htmlFor="a">Yes</label>
            </div>
            <div style={{marginLeft: '50px'}}>
            <input S  name="ipRegistered" id="b" type="radio" required value={2} checked={data?.ipRegistered==2} disabled/>
              <label htmlFor="b">No</label>
            </div>
            </div>
          </label>
          <label className="lable-for-apk" >
          <span className="xs">Company Incorporated?</span>
          <div className="rs">
            <div >
            <input  name="incorporated" id="c" type="radio" required value={1} checked={data?.incorporated == 1} disabled/>
              <label htmlFor="c">Yes</label>
            </div>
            <div style={{marginLeft: '50px'}}>
            <input  name="incorporated" id="d" type="radio" required value={2}checked={data?.incorporated == 2 } disabled/>
              <label htmlFor="d">No</label>
            </div>
          </div>
          </label>
          <label className="lable-for-apk">
            <span className="xs">EIN</span>
            <input disabled value={data?.ein} className="in" type="text" name="ein" />
          </label>
          </div>
          <div className="right-part">
          <label className="lable-for-apk">
            <span className="xs">Year Founded</span>
            <select disabled value={data?.yearFounded} className="in" id="yearFounded" name="yearFounded" required >
            <option selected disabled></option>
            <option value="2023">2023</option>
              <option value="2022">2022</option>
              <option value="2021">2021</option>
              <option value="2020">2020</option>
              <option value="2019">2019</option>
              <option value="2018">2018</option>
              <option value="2017">2017</option>
              <option value="2016">2016</option>
              <option value="2015">2015</option>
              <option value="2014">2014</option>
              <option value="2013">2013</option>
              <option value="2012">2012</option>
              <option value="2011">2011</option>
              <option value="2010">2010</option>
              <option value="2009">2009</option>
              <option value="2008">2008</option>
            </select>
          </label>
          <label className="lable-for-apk">
            <span className="xs">LinkedIn</span>
            <input disabled value={data?.linkedin} className="in" id="linkedin" name="linkedin" required />
          </label>
          <label className="lable-for-apk">
            <span className="xs">Industry</span>
            <select disabled value={data?.industry} className="in" id="industry" name="industry" required >
            <option selected disabled></option>
              <option value={1}>Fintech</option>
              <option value={2}>Healthcare</option>
              <option value={3}>Agriculture</option>
              <option value={4}>AI</option>
              <option value={5}>Deeptech</option>
              <option value={6}>Security</option>
              <option value={7}>Data Science</option>
              <option value={8}>Other</option>
            </select>
          </label>
          <label className="lable-for-apk">
            <span className="xs">Website</span>
            <input disabled value={data?.website} className="in" id="website" name="website" required />
          </label>
          <label className="lable-for-apk">
            <span className="xs">Business Model</span>
            <select disabled value={data?.businessModel} className="in" id="businessModel" name="businessModel" required >
            <option selected disabled></option>
            {businessModels.map((value)=>{
             return <option value={value.label.toLowerCase()}>{value.label}</option>
            })}
            </select>
          </label>
          </div>
          </div>
          <div className="Part-container">
            <div className="inputs-wrapper">
              <div className="left-part">
                <label className="lable-for-apk">
                  <span className="xs">Planned Revenue in 1 year in USD</span>
                  <NumericFormat disabled thousandSeparator="," value={data?.plannedRevenueInYear} className="in" type="text" name="plannedRevenueInYear" required />
                </label>
                <label className="lable-for-apk">
                  <span className="xs">Planned Revenue in 3 year in USD</span>
                  <NumericFormat disabled thousandSeparator="," value={data?.plannedRevenueInThreeYear} className='in' type="text" name="plannedRevenueInThreeYear" required />
                </label>
                <label className="lable-for-apk">
                  <span className="xs">Runway Months</span>
                  <NumericFormat disabled thousandSeparator="," value={data?.runawayMonths} className='in' type="text" name="runawayMonths" required />
                </label>
                <label className="lable-for-apk">
                  <span className="xs">Client Retention Rate (%)</span>
                  <NumericFormat disabled thousandSeparator=","value={data?.clientRetention} className='in' type="text" name="clientRetention" />
                </label>
              </div>
              <div className="right-part-info">
                <label className="lable-for-apk">
                  <span className="xs">Revenue During last 12 months in USD</span>
                  <NumericFormat disabled thousandSeparator="," value={data?.plannedRevenueLast12Months} className='in' type="text" name="revenueTwelve" />
                </label>
                <label className="lable-for-apk">
                  <span className="xs">Funds Received in USD</span>
                  <NumericFormat disabled thousandSeparator="," value={data?.fundsReceived} className="in" id="fundsReceived" name="fundsReceived" required />
                </label>
                <label className="lable-for-apk">
                  <span className="xs">Desired Amount to raise in USD</span>
                  <NumericFormat disabled thousandSeparator="," value={data?.desiredAmountToRaise} className="in" id="desiredAmount" name="desiredAmount" required />
                </label>
              </div>
            </div>
            <div className="textareas">
              <label className="lable-for-apk">
                <span className="xs">Name Main Competitors</span>
                <textarea disabled value={data?.mainCompetitors} name="mainCompetitors" className="in-area" rows='6'></textarea>
              </label>
              <label className="lable-for-apk">
                <span className="xs">What is your Competitive Advantage</span>
                <textarea disabled value={data?.competitiveAdvantage} name="competitiveAdvantage" className="in-area" rows='6'></textarea>
              </label>
            </div>
            <div className="radios-wrapper-mobile">
            <span className="xs">Evaluate your Product on being faster than Competitors</span>
            <label className="lable-for-apk-number">
                
                <Autocomplete className="inM" id="a" name="fast" required
                size="small"
                value={+data?.fast}
                options={numbers}
                disabled
                renderInput={(params) => <TextField {...params}/>}
                />
            </label>
            <span className="xs">Evaluate your Product on being more client satisfactory than Competitors</span>
            <label className="lable-for-apk-number">
                <Autocomplete className="inM" id="k" name="satisfactory" required
                size="small"
                options={numbers}
                disabled
                value={+data?.satisfactory}
                renderInput={(params) => <TextField {...params}/>}
                />
            </label>
            <span className="xs">Evaluate your Product on being easier to use than Competitors</span>
            <label className="lable-for-apk-number">
                <Autocomplete className="inM" id="u" name="easier" required
                size="small"
                options={numbers}
                disabled
                value={+data?.easier}
                renderInput={(params) => <TextField {...params}/>}
                />
            </label>
            <span className="xs">Evaluate your Product on being more budget friendly than Competitors</span>
                <label className="lable-for-apk-number">
                <Autocomplete className="inM" id="a5" name="budget" required
                size="small"
                options={numbers}
                disabled
                value={+data?.budget}
                renderInput={(params) => <TextField {...params}/>}
                />
            </label>
            </div>
            <div className="radios-wrapper">
            <label className="lable-for-apk">
              <span className="xs">Evaluate your Product on being faster than Competitors</span>
              <div className='radio-flex'>
                <div>
                <div className="radio-wrapper">
                  <label htmlFor="a">1</label>
                <input disabled name="fast" id="a" type="radio" checked={data?.fast==1} ></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="b">2</label>
                <input disabled name="competitors" id="b" type="radio" checked={data?.fast==2}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="c">3</label>
                <input disabled name="competitors" id="c" type="radio" checked={data?.fast==3}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="d">4</label>
                <input disabled name="competitors" id="d" type="radio"checked={data?.fast==4}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="e">5</label>
                <input disabled name="competitors" id="e" type="radio"checked={data?.fast==5}></input>
                </div>
                </div>
                <div>
                <div className="radio-wrapper">
                  <label htmlFor="f">6</label>
                <input disabled name="competitors" id="f" type="radio"checked={data?.fast==6}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="g">7</label>
                <input disabled name="competitors" id="g" type="radio"checked={data?.fast==7}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="h">8</label>
                <input disabled name="competitors" id="h" type="radio" checked={data?.fast==8}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="i">9</label>
                <input disabled name="competitors" id="i" type="radio" checked={data?.fast==9}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="j">10</label>
                <input disabled name="competitors" id="j" type="radio" checked={data?.fast==10}></input>
                </div>
                </div>
              </div>
            </label>
            <label className="lable-for-apk">
              <span className="xs">Evaluate your Product on being more client satisfactory than Competitors</span>
              <div className='radio-flex'>
              <div>
                <div className="radio-wrapper">
                  <label htmlFor="k">1</label>
                <input disabled name="satisfactory" id="k" type="radio"checked={data?.satisfactory==1}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="l">2</label>
                <input disabled name="satisfactory" id="l" type="radio" checked={data?.satisfactory==2}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="m">3</label>
                <input disabled name="satisfactory" id="m" type="radio"checked={data?.satisfactory==3}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="n">4</label>
                <input disabled name="satisfactory" id="n" type="radio"checked={data?.satisfactory==4}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="o">5</label>
                <input disabled name="satisfactory" id="o" type="radio"checked={data?.satisfactory==5}></input>
                </div>
                </div>
                <div>
                <div className="radio-wrapper">
                  <label htmlFor="p">6</label>
                <input disabled name="satisfactory" id="p" type="radio"checked={data?.satisfactory==6}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="q">7</label>
                <input disabled name="satisfactory" id="q" type="radio"checked={data?.satisfactory==7}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="r">8</label>
                <input disabled name="satisfactory" id="r" type="radio"checked={data?.satisfactory==8}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="s">9</label>
                <input disabled name="satisfactory" id="s" type="radio"checked={data?.satisfactory==9}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="t">10</label>
                <input disabled name="satisfactory" id="t" type="radio"checked={data?.satisfactory==10}></input>
                </div>
                </div>
              </div>
            </label>
            <label className="lable-for-apk">
              <span className="xs">Evaluate your Product on being easier to use than Competitors</span>
              <div className='radio-flex'>
              <div>
                <div className="radio-wrapper">
                  <label htmlFor="u">1</label>
                <input disabled name="easier" id="u" type="radio" checked={data?.easier==1} ></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="v">2</label>
                <input disabled name="easier" id="v" type="radio" checked={data?.easier==2}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="w">3</label>
                <input disabled name="easier" id="w" type="radio" checked={data?.easier==3}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="x">4</label>
                <input disabled name="easier" id="x" type="radio" checked={data?.easier==4}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="y">5</label>
                <input disabled name="easier" id="y" type="radio" checked={data?.easier==5}></input>
                </div>
                </div>
                <div>
                <div className="radio-wrapper">
                  <label htmlFor="z">6</label>
                <input disabled name="easier" id="z" type="radio" checked={data?.easier==6}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="a1">7</label>
                <input disabled name="easier" id="a1" type="radio"checked={data?.easier==7}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="a2">8</label>
                <input disabled name="easier" id="a2" type="radio" checked={data?.easier==8}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="a3">9</label>
                <input disabled name="easier" id="a3" type="radio" checked={data?.easier==9}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="a4">10</label>
                <input disabled name="easier" id="a4" type="radio"checked={data?.easier==10}></input>
                </div>
                </div>
              </div>
            </label>
            <label className="lable-for-apk">
              <span className="xs">Evaluate your Product on being more budget friendly than Competitors</span>
              <div className='radio-flex'>
              <div>
                <div className="radio-wrapper">
                  <label htmlFor="a5">1</label>
                <input disabled name="budget" id="a5" type="radio" checked={data?.budget==1} ></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="a6">2</label>
                <input disabled name="budget" id="a6" type="radio"  checked={data?.budget==2} ></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="a7">3</label>
                <input disabled name="budget" id="a7" type="radio"  checked={data?.budget==3} ></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="a8">4</label>
                <input disabled name="budget" id="a8" type="radio" checked={data?.budget==4}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="a9">5</label>
                <input disabled name="budget" id="a9" type="radio"  checked={data?.budget==5}></input>
                </div>
                </div>
                <div>
                <div className="radio-wrapper">
                  <label htmlFor="a10">6</label>
                <input disabled name="budget" id="a10" type="radio"  checked={data?.budget==6}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="a11">7</label>
                <input disabled name="budget" id="a11" type="radio"  checked={data?.budget==7}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="a12">8</label>
                <input disabled name="budget" id="a12" type="radio"  checked={data?.budget==8}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="a13">9</label>
                <input disabled name="budget" id="a13" type="radio"  checked={data?.budget==9}></input>
                </div>
                <div className="radio-wrapper">
                  <label htmlFor="a14">10</label>
                <input disabled name="budget" id="a14" type="radio"  checked={data?.budget==10}></input>
                </div>
                </div>
              </div>
            </label>
            </div>
      </div>
      <div className="Part-container">
          <h2 className="founders-title">Founders</h2>
          {data?.founders?.map((i,key)=>(  <>
            <div className="third-inputs-wrapper-info">
            <div className="third-left-part">
            <label className="lable-for-apk">
            {/* <h4 className="ceo">is Ceo? <input onChange={(e) =>editFounder(index, 'isCeo', !i?.isCeo)} defaultChecked={i?.isCeo} className="isCeo" name="isCeo" type="checkbox"></input></h4> */}
              <span className="xs">First Name</span>
              <input disabled value={i.firstName} key={key} className="in" type="text" name="firstName" required />
            </label>
            <label className="lable-for-apk">
            <span className="xs">Email</span>
            <input disabled value={i.email} key={key}  className="in" type="email" name="email" required />
          </label>
          <label className="lable-for-apk">
            <span className="xs">Linkedin URL</span>
            <input disabled value={i.linkedinUrl} key={key} className='in' type="text" name="linkedinUrl" required />
          </label>
          </div>
          <div className="third-right-part">
          <label className="lable-for-apk">
            <span className="xs">Last Name</span>
            <input disabled value={i.lastName} key={key} className="in" name="lastName" required />
          </label>
          {/* <label className="lable-for-apk">
            <span className="xs">Scientific experience</span>
            <input disabled value={i.scientificExperience} key={key} className="in" type="number" name="scientificExperience1" required />
          </label> */}
          </div>
          </div>
            </>))}
          <div className="finished">
            <div className="ss">
           
            <div className="end-part-wrapper-info">
            <div className="end-part-first">
          <label className="lable-for-apk">
            <span className="xs">Number Of Engineers</span>
            <NumericFormat disabled thousandSeparator="," value={data?.numberOfEngineers} className="in" type="number" name="numberOfEngineers" required />
          </label>
          <label className="lable-for-apk">
            <span className="xs">CEO C Level Experience</span>
            {/* data?.levelEx */}
            <NumericFormat disabled thousandSeparator="," value={data?.levelEx} className="in"  name="numberOfCEOExists"  required />
          </label>
          <label className="lable-for-apk">
            <span className="xs">Number of CEO Exits</span>
            <NumericFormat disabled thousandSeparator="," value={data?.numberOfCEOExists} className="in"   name="levelEx" required />
          </label>
          <div className="file-input-btn">
          <label  value={data?.documents} htmlFor="file-input" className="lable-for-apk">
            <input disabled
              id="file-input"
              type="file"
              name="documnets"
              accept=".pdf"
              style={{ display: "none" }}
            />
               <img style={{cursor:"pointer", width: '50%'}}/>
               </label>
            </div>
          </div>
          <div className="end-part-second">
          <label className="lable-for-apk">
            <span className="xs">Describe your Management Team Strength</span>
            <textarea disabled value={data?.describeManagement} rows={7} className="in-area" type="number" name="describeManagement" required />
          </label>
          </div>
          </div>
            </div>
          </div>
         
      </div> 
        </form>   
         
      </div> 
    </div>
    </Application>
    )
}

export default Info;