import React from "react";
import { SpinnerCircular } from "spinners-react";
import "./Hash.css"
import { AUTH_API_PATH } from "../../Pages/auth/apiPath";
import inst from "../../Pages/Application/api";
import { useEffect } from 'react';
import { toast } from 'react-toastify'
import { useNavigate, } from 'react-router-dom'
import { useParams } from 'react-router-dom';
import { useSearchParams } from "react-router-dom"
import {
  useLocation
} from "react-router-dom";

const Hash = () => {

    
  let [searchParams, setSearchParams] = useSearchParams()
  const hash = searchParams.get("hash")


  const navigate = useNavigate()

  useEffect(()=> {
    inst.put(AUTH_API_PATH + `/payments`, {
      hash
    }).then((response) => {
      navigate('/applications')
    }).catch((error) => {
    })
  }, [])

  return(
    <div className="spinner">
    <SpinnerCircular sx={{ margin: '20px' }}
            size="200"
            thickness="200"
            color="#4293EA"
            secondaryColor="silver" />
            </div>
  )
}

export default Hash;