import React from 'react'
import Header from "../../components/Header/Header";
import Menu from "../../components/Menu/Menu"
import { useState } from 'react';
import "./Score.css"

function Score({children}) {
    const [clicked,isClicked] = useState(true)

    const onClick = ()=>{
      return !clicked?isClicked(true):isClicked(false) 
    }
    
  return (
<div className='score-background'>
<Menu/>
<div className='score-container'>
      {children}
</div> 
</div>
  );
}

export default Score