import { useState,useEffect } from "react"
import React  from 'react'
import inst from './../Pages/Application/api'
import cc from "./../img/create.png"
import { Link } from "react-router-dom"
import Application from "../Pages/Application/Application"
import { $api } from "../api"
import {AUTH_API_PATH} from "./../Pages/auth/apiPath"
import Applications from "./Applicatons/Applications"
import {useDispatch, useSelector} from "react-redux";
import {setApplications} from "../State/slice";
import { formatDate } from "../utils/dateFormat";


function Allapk(props) {
    const [paymentsStatus, setPaymentsStatus] = useState(3)
    const [applications, setApplications] = useState([])

    useEffect(()=>{
      window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
      inst.get(AUTH_API_PATH+'/applications/me')
        .then(response => {
            const normalize = response.data.applications.map(a =>{
                const calculateScore =(dms)=> {
                  return JSON.parse(dms).totalScore
                }
                return {
                    founders:a?.founders,
                    plannedRevenueInYear:a?.plannedRevenueInYear,
                    id: a.id,
                    startupName: a.startupName,
                    createdAt: a.createdAt,
                    score: a.dms? calculateScore(a.dms) : null,
                    status: a.payments ? a.payments[a.payments.length-1]?.status : null,
                    user: a?.user?.investorCompany
                }
            })
            setApplications(normalize)
        })
        .catch(error => {
          console.error(error);
        });
    }, []);



  return (
    <div>
<Application>
<div className='Allapk'>
<div className='header1'>
<div className='petqa123'>
<h1 className='startup-name'>Scores</h1>
<Link to ="/application/step1/0">
<button className='create-button' onClick={props.onClick1} >+ Add Score</button>
</Link>
</div>
</div>
    <div className="containerforcollection" id="elemnt">
        { applications.sort((a,b)=>b.id-a.id).map((i,key)=>{
              return(<Applications plannedRevenueInYear={i.plannedRevenueInYear} founders={i.founders} setPaymentsStatus={setPaymentsStatus} paymentsStatus={i?.status}  key={key} id={i.id} createdAt={formatDate(new Date(i.createdAt))} score={Math.round(i.score)} startupName={i.startupName} user = {i?.user}/>)
            }
        )}
    </div>
</div>
</Application> 
</div>
  )
}

export default Allapk