import React from 'react'
import { Link } from 'react-router-dom';
import logo1 from "./../../img/logo.png"
import Logout from '../Logout';
import "./Menu.css"
import Application from '../../img/application.png'

function Menu(props) {
  return (
  <div className='multic'>
    <div className='logo-wrapper'>
    <img src={logo1} className='logo1'/>
    <div className='vector_2'></div>
    {/*<div className='multic-titles-wrapper'>*/}
    {/*        <h1>WORLDWIDE INNOVATIONS</h1>*/}
    {/*        <h5>IP Protection & Acquisition. Financing</h5>*/}
    {/*      </div>*/}
    </div>
    <div className='menu'> 
    <div className='navigate'>
    <div className='navbar'>
       <Link to = "/applications" className='forNavigate'><div className='links'>Score</div></Link>
       {/* <Link to="/scores"className='forNavigate'><div className='links'>Score</div></Link> */}
    </div>
    <div className="logout">
    <div className='links-logout'>
       <Logout/>
      </div>
    </div>
    
    </div>
    
      {/* <div className='navigate'>
     
      </div>
      */}
  </div>
</div>
  )
}

export default Menu